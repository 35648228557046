import React, { Component, createRef } from "react";
import { connect } from "react-redux";
// import copy from "copy-to-clipboard";
import copy from 'clipboard-copy';
import { bindActionCreators } from "redux";
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import {
  Container,
  Grid,
  Typography,
  FormGroup,
  Button,
  Link,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Paper,
  MenuItem,
  Box,
  FormHelperText,
  Slider as MuiSlider,
  Chip,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Input,
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
// import Slider from '@mui/material/Slider'
import { alpha } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import imageNotAvailable from '../../../../assests/images/img-soon.png';
import { formatDate, getComponentActionList, isActionAllowed, showErrorToast, showSuccessToast } from "../../../../common/components/Helper/Utils";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {
  fetchInventoryWatches,
  brandList,
  dialColor,
  filterBySearch,
  inventoryDetailById,
  SearchInventoryWatches,
  fetchInventoryExcelList,
  deleteInventories,
  shareInventories,
} from "../actions/dashboardAction";
import _ from "lodash";
import searchinventry from "../actions/dashboardaapi";
import plusSign from "../../../../assests/images/Group 1771.png";
import Card from "@material-ui/core/Card";
import InvoiceModal from "./invoiceModal";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import IconButton from "@material-ui/core/IconButton";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { SpinnerRoundFilled } from "spinners-react";
import ViewInvoicemodel from "./ViewInvoicemodel";
import VoidInventory from "./voidInventory";
import { voidInventoryUpdate } from "./../../dashboard/actions/dashboardAction";
import { trim } from "lodash";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
// import { th } from "date-fns/locale";
import ContentWrapper from "../../../../common/components/contentWrapper";
import ReasonChange from './SoldAndUnsoldModel';
import BulkUpload from '@mui/icons-material/UploadFileOutlined';
import Share from '@mui/icons-material/Share';
import constants from "../../../../utils/constants";
import { getInventorySetting } from '../../administration/actions/administration';
import { recoverPassword, verifyOtp } from "../../../Auth/reducer/actions";
import validator from 'validator';
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "react-loader-spinner";
import AccessRestricted from "../../common/components/accessRestricted";
import UpgradePlan from "../../common/components/upgradePlan";

// import Slider from 'react-slick';
const InventoryStatusLookup = {
  "1": "Inhand",
  "2": "Incoming",
  "3": "Out on Memo",
  "4": "On Hold / Reserved",
  "5": "Available to Order",
  "6": "Needs Service / Polish",
  "7": "At Service",
  "8": "Need to Ship",
  "9": "Shipped waiting on payment",
  "10": "Sold & Cashed Out",
  "11": "Intake Complete",
};

const StyledMenu = styled((props) => (
  <Menu
    // elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BasicMenu = ({ row, searchWatch, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [viewInvoiceModal, setviewInvoiceModal] = React.useState(false);
  const [voidInventoryModal, setvoidInventoryModal] = React.useState(false);
  const [selectedInventory, setSelectedInventory] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setviewInvoiceModal(false);
    setvoidInventoryModal(false);
    setvoidInventoryModal(false);
    setSelectedInventory(null);
  };

  const viewInvoice = (row) => {
    setAnchorEl(null);
    setviewInvoiceModal(!viewInvoiceModal);
    setSelectedInventory(row);
  };

  const voidInvoice = (row) => {
    setAnchorEl(null);
    setvoidInventoryModal(!voidInventoryModal);
    setSelectedInventory(row);
  };

  const updateInventoryDetails = (reason) => {

    if (trim(reason) !== "") {
      const data = {
        inventoryid: selectedInventory.inventoryid,
        reason: trim(reason),

      };
      voidInventoryUpdate(data, (err, response) => {
        if (response) {
          handleClose(true);
          searchWatch();
        }
        if (err) {
          handleClose(true);
          showErrorToast(err)
        }
      });
    }
  };

  return (
    <div>
      <IconButton
        // className="primary-bg color-white"
        type="button"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ marginLeft: "5px", padding: "5px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {row.status === "Active" && row.sold !== 1 && (
          <MenuItem
            onClick={() => {
              voidInvoice(row);
            }}
          >
            Void Inventory
          </MenuItem>
        )}
        {row.sold === 1 && row.status !== "Void" &&
          (row.invoiceid ? (
            <MenuItem
              onClick={() => {
                viewInvoice(row);
              }}
            >
              View Invoice
            </MenuItem>
          ) : (
            <MenuItem disabled>Invoice Not Associated</MenuItem>
          ))}

      </StyledMenu>
      {viewInvoiceModal && (
        <ViewInvoicemodel
          open={viewInvoiceModal}
          handleClose={handleClose}
          invoiceid={{
            id: selectedInventory.invoiceid,
          }}
        />
      )}
      {voidInventoryModal && (
        <VoidInventory
          open={voidInventoryModal}
          handleClose={handleClose}
          inventory={selectedInventory}
          updateInventoryDetails={updateInventoryDetails}
        />
      )}
    </div>
  );
};

const CustomizedUploadMenus = ({ downloadInventory, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    setAnchorEl(null);
    if (data) {
      downloadInventory(data)
    }
  };
  const bulUploadStandaWatch = () => {
    history.push({
      pathname: "/bulk-upload",
    });
  }
  const bulUploadMiscBucket = () => {
    history.push({
      pathname: "/misc-bucket-bulk-upload",
    });
  }
  const bulUploadMiscProduct = () => {
    history.push({
      pathname: "/misc-product-bulk-upload",
    });
  }
  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        className={
          "text-capitalize primary-bg color-white"
        }
        style={{ float: "right" }}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        <BulkUpload />&nbsp;BulK Upload
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={
          bulUploadStandaWatch
        } disableRipple>
          BulK Upload For Standard Watches
        </MenuItem>
        <MenuItem onClick={bulUploadMiscBucket} disableRipple>
          BulK Upload For Misc Watches
        </MenuItem>
        <MenuItem onClick={bulUploadMiscProduct} disableRipple>
          BulK Upload For Misc Products
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const CustomizedMenus = ({ downloadInventory }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    setAnchorEl(null);
    if (data) {
      downloadInventory(data)
    }
  };

  return (
    <>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        className={
          "text-capitalize primary-bg color-white"
        }
        style={{ float: "right" }}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        Download Inventory
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("PDF")} disableRipple>
          Download Pdf
        </MenuItem>
        <MenuItem onClick={() => handleClose("EX")} disableRipple>
          Download Excel
        </MenuItem>
      </StyledMenu>
    </>
  );
};

const followersMarks1 = [
  {
    value: "0",
    scaledValue: 0,
    label: "ALL",
  },
  {
    value: "Unknown",
    scaledValue: 1,
    label: "UNKNOWN",
  },
  {
    value: 30,
    scaledValue: 2,
    label: "30 d",
  },
  {
    value: 60,
    scaledValue: 3,
    label: "60 d",
  },
  {
    value: 90,
    scaledValue: 4,
    label: "90 d",
  },
  {
    value: 120,
    scaledValue: 6,
    label: "120 d",
  },
  {
    value: 150,
    scaledValue: 8,
    label: "150 d",
  },
  {
    value: 180,
    scaledValue: 9,
    label: "180 d",
  },
];

const PriceRangeObj = [
  {
    value: "0",
    scaledValue: 0,
    label: "0",
  },
  // {
  //   value: "Unknown",
  //   scaledValue: 1,
  //   label: "UNKNOWN",
  // },
  {
    value: 5000,
    scaledValue: 2,
    label: "$5k",
  },
  {
    value: 10000,
    scaledValue: 3,
    label: "$ 10k",
  },
  {
    value: 25000,
    scaledValue: 4,
    label: "$ 25k",
  },
  {
    value: 50000,
    scaledValue: 6,
    label: "$ 50k",
  },
  {
    value: 75000,
    scaledValue: 8,
    label: "$ 75k",
  },
  {
    value: 100001,
    scaledValue: 10,
    label: "$ 100k+",
  },
];

class InventoryComponent extends Component {
  constructor(props) {
    super(props);
    this.textRef = React.createRef();
    this.state = {
      render: false,
      searchEnabled: false,
      inventoryid: "",
      productid: "",
      purchaseprice: "",
      // purchasedate: "",
      modledata: "",
      purchasefrom: "",
      CaseSize: "",
      CaseMaterial: "",
      DialColor: "",
      BandMaterial: "",
      disable: "",
      filterEnable: false,
      selectedProduct: { productId: "" },
      sold: true,
      avail: false,
      soldbtn: false,
      BrandName: "",
      brand_id: "",
      brand_description: "",
      InventoryType: "",
      InventoryStatus: '',
      key: "",
      data: [],
      searchString: "",
      nonsearchstring: true,
      updatedInvenentryList: [],
      updateIntiate: false,
      errorMessage: "",
      inventory_id: "",
      listed_on: "all",
      sold_status: "",
      loaded: false,
      UserId: "",
      purchasedate: 0,
      autocomplete: {
        currentInput: "",
        loading: false,
        data: [],
      },
      priceRange: [0, 100001],
      cursor: 0,
      inventoryData: [],
      selected: { product: null },
      copied: false,
      setCopied: false,
      soldStatus: "0",
      paidStatus: "ALL",
      dataSoldUnsold: false,
      removedSoldUnsold: "",
      open: false,
      rowsPerPage: 10,
      page: 0,
      downloadloading: false,
      inventoryxlid: this.props?.match?.params?.bulkUploadId
        ? this.props?.match?.params?.bulkUploadId
        : "",
      anchorE2: null,
      openDropDown: false,
      inventoryStatus: "ALL",
      openE2: false,
      InventoryCategory: null,
      type: 'standand_and_misc',
      accessData: null,
      accountId: sessionStorage.getItem("accountId"),
      userData: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null,
      reasonChange: '',
      openReasonChange: false,
      rowDetails: '',
      soldText: 'Sold Reason:',
      unsoldText: 'Unsold Reason:',
      setting: {},
      notforSale: false,
      hoveredCard: null,
      selectedItems: [],
      openDeleteModal: false,
      openOtpModel: false,
      showing: false,
      timerotp: '',
      otp: '',
      otpErrorText: '',
      twoMinutes: 60 * 2,
      isValid: false,
      disabled: false,
      isDeleteAll: false,
      openShareModal: false,
      pagecontent: 100,
      pagenumber: 1,
      noItemFound: null,
      noOfItemsInventory: 0,
      totalCostforInventory: 0,
      productImage: "ALL",
      selectAll: false
    };
    this.timerRef = null;
    this.changeRangeName = this.changeRangeName.bind(this);
    this.SearchInventoryWatchesdetails =
      this.SearchInventoryWatchesdetails.bind(this);
    this.handleNotforSaleCheck = this.handleNotforSaleCheck.bind(this);
    this.handleChangeOfBrandByBrandName =
      this.handleChangeOfBrandByBrandName.bind(this);
    this.handleChangeOfInventoryDetailById =
      this.handleChangeOfInventoryDetailById.bind(this);
    this.autocomplete = this.autocomplete.bind(this);
    this.checkboxval = this.checkboxval.bind(this);
    this.removebrandname = this.removebrandname.bind(this);
    this.handleChangeOfPaidStatus = this.handleChangeOfPaidStatus.bind(this);
    this.removePaidStatus = this.removePaidStatus.bind(this);
    this.handleChangeOfSoldUnSold = this.handleChangeOfSoldUnSold.bind(this);
    this.handleEnterEvent = this.handleEnterEvent.bind(this);
    this.removeSoldUnsold = this.removeSoldUnsold.bind(this);
    this.removeBulkUploadId = this.removeBulkUploadId.bind(this);
    this.removeInventoryType = this.removeInventoryType.bind(this);
    this.removeInventoryStatus = this.removeInventoryStatus.bind(this);
    this.handleChangeOfBulkUploadId =
      this.handleChangeOfBulkUploadId.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadInventory = this.downloadInventory.bind(this);
    // this.createInvoice = this.createInvoice(this);
    this.inventoryPurchaseDateInterval =
      this.inventoryPurchaseDateInterval.bind(this);
    this.inventoryPriceRangeInterval =
      this.inventoryPriceRangeInterval.bind(this);
    this.valueLabelFormat = this.valueLabelFormat.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleChangeOfInventoryStatus =
      this.handleChangeOfInventoryStatus.bind(this);
    this.removetype = this.removetype.bind(this);
    this.handleChangeOfDialColor = this.handleChangeOfDialColor.bind(this);
    this.removeInventoryCategory = this.removeInventoryCategory.bind(this);
    // this.removeDialColor = this.removeDialColor.bind(this);
    this.handleCloseOfReasonChange = this.handleCloseOfReasonChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
    this.getInventorySettingDetails = this.getInventorySettingDetails.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.openOtpModel && !prevState.openOtpModel) {
      this.resetTimer();
    }
  }

  resetTimer = () => {
    this.setState({ showing: false, twoMinutes: 60 * 2 }, this.startTimer);
  };

  startTimer = () => {
    if (this.timerRef) {
      clearInterval(this.timerRef);
    }
    let timer = this.state.twoMinutes;
    this.timerRef = setInterval(() => {
      let minutes = parseInt(timer / 60, 10);
      let seconds = parseInt(timer % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      if (timer > 0) {
        this.setState({
          twoMinutes: `${minutes}:${seconds}`
        });
      } else {
        clearInterval(this.timerRef);
        this.setState({ showing: true });
      }

      timer -= 1;
    }, 1000);
  }

  componentWillMount() {
    const accessData = getComponentActionList('my_inventory')
    this.setState({ accessData: accessData })
  }

  getInventorySettingDetails() {
    getInventorySetting((err, response) => {
      if (response && response.data && response.data.length > 0) {
        const data = response.data[0] || {};
        this.setState({ setting: data });
      }
    })
  }

  componentDidMount() {
    // const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
    // this.setState({userData: userDetails});
    this.props.brandList();
    this.getInventorySettingDetails()
    if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.location.state.inventoryCacheState) {
      this.setState(prevState => ({
        ...prevState,
        ...this.props.location.state.inventoryCacheState
      }));
      setTimeout(() => {
        this.SearchInventoryWatchesdetails()
      }, 200)
    }
    else {
      this.setState({
        UserId:
          this.props && this.props.currentUser && this.props.currentUser.userId,
      });
      // setTimeout(() => {
      //   this.setState({ loaded: true });
      // }, 2000);
      const token = localStorage.getItem("token");
      if (token) {
        if (this.props.currentUser) {
          // this.props.fetchInventoryWatches(this.state.UserId);
        }
        let PId = null;
        try {
          PId = this.props.match.params.productId;
          if (PId) {
            this.state.selectedProduct.productId = PId;
            this.setState({
              ...this.state,
            });
            this.props.fetchSearchBrowseWatches(PId).then(() => {
              this.setState({ loaded: true });
            }).catch((error) => {
              console.error("Error fetching watches:", error);
              this.setState({ loaded: true });
            });
          } else {
            // this.props.fetchBrowseWatches(); // frequent error recived in the console
          }
        } catch (error) {
          console.log(error);
        }
        //this.props.dialColor();
        this.checkboxval();
      } else {
        window.location.href = "/login";
      }
    }
    //this.props.inventoryDetailById( this.state.UserId);
  }

  SearchInventoryWatchesdetails(userObject = null) {
    const userId = this.state.userData && this.state.userData.userId;
    const id = userId ? userId : userObject ? userObject.userId : null;
    this.setState({ loaded: false, pagenumber: 1, selectedItems: [], isDeleteAll: false }, () => {
      let keySearch = this.state.key;
      let searchData = "";
      if (keySearch) {
        searchData = keySearch.replace(/[&\/\\,+!@^&()$~%.'":;-?<>{}]/g, "");
        searchData = searchData.replaceAll("-", "");
        searchData = searchData.trim()
      }
      let data = {
        userid: this.state.userData.userId,
        roleid: this.state.userData.roleId,
        brand_id: this.state.brand_id,
        brand_name: this.state.brand_description,
        key: searchData,
        // is_notsale: this.state.notforSale.toString(),
        invoicestatus: this.state.paidStatus,
        sold: this.state.soldStatus,
        dial_color: this.state.DialColor,
        // inventory_status: this.state.inventoryStatus,
        inventoryxlid: this.state.inventoryxlid,
        inventory_type: this.state.InventoryType,
        inventory_status: this.state.InventoryStatus,
        purchace_date:
          this.state.purchasedate === 0
            ? "ALL"
            : this.state.purchasedate > 180
              ? "UNKNOWN"
              : this.state.purchasedate,
        type: this.state.type,
        category: this.state.InventoryCategory,
        product_image: this.state.productImage
      };
      if (data.type === "ALL") {
        delete data.type
      }
      if (data.category === "ALL") {
        delete data.category
      }
      if (
        data.brand_id !== "" ||
        data.key !== "" ||
        data.sold !== "" ||
        data.inventoryxlid !== "" ||
        data.inventory_type !== "" ||
        data.inventory_status !== "" ||
        data.purchace_date !== "" ||
        data.type !== "ALL" ||
        data.category !== "ALL" ||
        data.product_image !== "ALL"
      ) {
        this.setState({ searchEnabled: true });
      }
      if (data.sold === "") {
        data.sold = "ALL"
      }
      data['priceRange'] = {
        min: this.state.priceRange[0],
        max: this.state.priceRange[1] > 100000 ? null : this.state.priceRange[1]
      }
      if (data.priceRange.min == 0 && data.priceRange.max == null) {
        delete data.priceRange
      }
      // const inventoryDetails = this.props.SearchInventoryWatches(data, this.state.currentUser.userId);
      searchinventry.addinvetrysearchdataa(data, this.state.accountId, this.state.pagecontent, this.state.pagenumber).then((res) => {
        if (res.data && res.data.inventoryData) {
          this.setState({ inventoryData: res.data.inventoryData, page: 0, loaded: true });
        } else {
          this.setState({ inventoryData: [], page: 0, loaded: true });
        }
        if (res.data.success === 1) {
          this.setState((prevState) => ({
            updatedInvenentryList: res.data.data,
            updateIntiate: true,
            loaded: true,
            noItemFound: res.data.data?.length === 0 ? "No More Items Left" : null,
            noOfItemsInventory: res.data?.totalInventory ?? 0,
            totalCostforInventory: res.data?.totalCost ?? 0
          }));
        } else if (res.data.success === 0) {
          this.setState({
            updatedInvenentryList: [],
            updateIntiate: false,
            errorMessage: res.data.message,
            loaded: true,
            // noItemFound: res.data?.data?.length === 0 ? "Error" : null
          });
        } else {
          this.setState({
            errorMessage: res.message,
            loaded: true,
            // noItemFound: res.data?.data?.length === 0 ? "Error" : null
          });
        }
      });
    });
  }

  fetchMoreData = (inventoryList) => {
    let scrollTop = document.documentElement.scrollHeight
    if (scrollTop >= 800) {
      this.setState((prevState) => ({
        pagenumber: prevState.pagenumber + 1
      }), () => {
        const { pagenumber, pagecontent } = this.state;
        this.setState({ isDeleteAll: false, selectAll: false });
        let keySearch = this.state.key;
        let searchData = "";
        if (keySearch) {
          searchData = keySearch.replace(/[&\/\\,+!@^&()$~%.'":;-?<>{}]/g, "");
          searchData = searchData.replaceAll("-", "");
          searchData = searchData.trim()
        }
        let data = {
          brand_id: this.state.brand_id,
          roleid: this.state.userData.roleId,
          brand_name: this.state.brand_description,
          key: searchData,
          // is_notsale: this.state.notforSale.toString(),
          invoicestatus: this.state.paidStatus,
          sold: this.state.soldStatus,
          dial_color: this.state.DialColor,
          // inventory_status: this.state.inventoryStatus,
          inventoryxlid: this.state.inventoryxlid,
          inventory_type: this.state.InventoryType,
          inventory_status: this.state.InventoryStatus,
          purchace_date:
            this.state.purchasedate === 0
              ? "ALL"
              : this.state.purchasedate > 180
                ? "UNKNOWN"
                : this.state.purchasedate,
          type: this.state.type,
          category: this.state.InventoryCategory,
          product_image: this.state.productImage
        };
        if (data.type === "ALL") {
          delete data.type
        }
        if (data.category === "ALL") {
          delete data.category
        }
        if (
          data.brand_id !== "" ||
          data.key !== "" ||
          data.sold !== "" ||
          data.inventoryxlid !== "" ||
          data.inventory_type !== "" ||
          data.inventory_status !== "" ||
          data.purchace_date !== "" ||
          data.type !== "ALL" ||
          data.category !== "ALL" ||
          data.product_image !== "ALL"
        ) {
          this.setState({ searchEnabled: true });
        }
        if (data.sold === "") {
          data.sold = "ALL"
        }
        data['priceRange'] = {
          min: this.state.priceRange[0],
          max: this.state.priceRange[1] > 100000 ? null : this.state.priceRange[1]
        }
        if (data.priceRange.min == 0 && data.priceRange.max == null) {
          delete data.priceRange
        }
        searchinventry.addinvetrysearchdataa(data, this.state?.accountId, pagecontent, pagenumber).then((res) => {
          if (res.data && res.data.inventoryData) {
            this.setState({ inventoryData: res.data.inventoryData, page: 0 });
          } else {
            this.setState({ inventoryData: [], page: 0 });
          }
          if (res.data.success === 1) {
            this.setState({
              updatedInvenentryList: [...inventoryList, ...res.data.data],
              updateIntiate: true,
              noItemFound: res.data.data?.length === 0 ? "No More Items Left" : null
            });
          } else if (res.data.success === 0) {
            this.setState({
              updatedInvenentryList: [],
              updateIntiate: false,
              errorMessage: res.data.message,
              // noItemFound: res.data?.data?.length === 0 ? "Error" : null
            });
          } else {
            this.setState({
              errorMessage: res.message,
              // noItemFound: res.data?.data?.length === 0 ? "Error" : null
            });
          }
        });
      });
    }
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ openDropDown: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ openDropDown: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }

  handleClick(event, row) {
    // this.setState({anchorE2: event.currentTarget});
  }

  checkboxval(input) {
    const id = input?.id;
    const sold = input?.sold;
    if (
      this.props.InventoryList &&
      this.props.InventoryList.data &&
      this.props.InventoryList.data.length > 0 &&
      id
    ) {
      const inventoryList = this.props.InventoryList.data;
      const indexPosition = inventoryList.findIndex(singleInventory => {
        if (singleInventory.inventoryid === id) {
          return singleInventory
        }
      })
      if (indexPosition > -1) {
        if (sold === true) {
          this.props.InventoryList.data[indexPosition].sold = 1;
        } else {
          this.props.InventoryList.data[indexPosition].sold = 0;
        }
      }
    } else if (
      this.props.InventoryList &&
      this.props.InventoryList.data &&
      this.props.InventoryList.data.length > 0
    ) {
      for (let i = 0; i < this.props.InventoryList.data.length; i++) {
        if (this.props.InventoryList.data[i].sold == 1) {
          this.setState({ soldbtn: true });
        } else {
          this.setState({ soldbtn: false });
        }
      }
    }
    if (id && this.state.updatedInvenentryList) {
      let inventoryList = this.state.updatedInvenentryList;
      const indexPosition = inventoryList.findIndex(singleInventory => {
        if (singleInventory.inventoryid === id) {
          return singleInventory
        }
      })
      if (indexPosition > -1) {
        if (sold === true) {
          inventoryList[indexPosition].sold = 1;
        } else {
          inventoryList[indexPosition].sold = 0;
        }
        this.setState({ updatedInvenentryList: inventoryList, updateIntiate: true });
      }
      // if (inventoryList && inventoryList.length > 0) {
      //   for (let i = 0; i < inventoryList.length; i++) {
      //     if (id && inventoryList[i].inventoryid === id) {
      //       if (sold === true) {
      //         inventoryList[i].sold = 1;
      //       } else {
      //         inventoryList[i].sold = 0;
      //       }
      //       this.setState({ updatedInvenentryList: inventoryList });
      //     }
      //   }
      // }
    }
  }

  async componentWillReceiveProps(newProps) {
    const { pagecontent, pagenumber } = this.state;
    if (newProps.currentUser && !this.state.currentUser) {
      this.setState({
        ...this.state,
        currentUser: newProps.currentUser,
      });
      let keySearch = this.state.key;
      let searchData = "";
      if (keySearch) {
        searchData = keySearch.replace(/[&\/\\,+!@^&()$~%.'":;-?<>{}]/g, "");
        searchData = searchData.replaceAll("-", "");
        searchData = searchData.trim()
      }
      const data = {
        key: searchData,
        dial_color: this.state.DialColor,
        userid: this.state.userData.userId,
        brand_id: this.state.brand_id,
        brand_name: this.state.brand_description,
        sold: this.state.soldStatus,
        // is_notsale:this.state.notforSale.toString(),
        invoicestatus: this.state.paidStatus == '' ? "ALL" : this.state.paidStatus,
        // inventory_status: this.state.inventoryStatus,
        inventoryxlid: this.state.inventoryxlid,
        inventory_type: this.state.InventoryType,
        inventory_status: this.state.InventoryStatus,
        purchace_date:
          this.state.purchasedate === 0
            ? "ALL"
            : this.state.purchasedate > 180
              ? "UNKNOWN"
              : this.state.purchasedate,
        type: this.state.type,
        category: this.state.InventoryCategory,
        product_image: this.state.productImage
      };
      if (data.type === "ALL") {
        delete data.type
      }
      if (data.category === "ALL") {
        delete data.category
      }
      await this.props.fetchInventoryWatches(this.state?.accountId, data, pagecontent, pagenumber);
      this.setState({
        totalCostforInventory: this.props.InventoryList?.totalCost,
        noOfItemsInventory: this.props.InventoryList?.totalInventory,
        noItemFound: this.props.InventoryList?.data?.length === 0 ? "No data found" : null,
        loaded: true
      });
      this.props.fetchInventoryExcelList(newProps.currentUser.userId);
      //this.props.inventoryDetailById(newProps.currentUser.inventoryid);
      const bulkUploadId = this.props?.match?.params.bulkUploadId || null;
      if (bulkUploadId) {
        this.SearchInventoryWatchesdetails(newProps.currentUser);
      }
    }
  }

  handleChangeOfBrandByBrandName = (event) => {
    let brand = event.target.value;
    this.setState({
      brand_id: brand.brandid,
      brand_description: brand.brandname,
    });
    let brandName = "";
    if (brand.brandid == "ALL") {
      brandName = "";
    } else {
      brandName = brand.brandid;
    }
    this.setState({
      BrandName: brandName,
      modledata: brand.brandname,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // this.props.filterBySearch(data);
  };

  valueLabelFormat = (value) => {
    let scaledValue = value;
    if (value === "0") {
      scaledValue = "ALL";
    } else if (value > 180) {
      scaledValue = "UNKNOWN";
    } else {
      scaledValue = value;
    }
    return `${scaledValue}`;
  };

  valuePriceRangeFormat = (value) => {
    let scaledValue = value;
    if (value === "0") {
      scaledValue = "0";
    } else if (value > 120000) {
      scaledValue = "UNKNOWN";
    } else {
      scaledValue = value;
    }
    return `${scaledValue}`;
  };

  inventoryPurchaseDateInterval = (value) => {
    let purchase = value;
    // let purchaseDate = "";
    // if (purchase === "ALL") {
    //     purchaseDate = "0";
    // } else {
    //     purchaseDate = purchase;
    // }
    this.setState({
      purchasedate: purchase,
    });
    // setTimeout(() => {
    //     this.SearchInventoryWatchesdetails();
    // }, 1000);
  };

  inventoryPriceRangeInterval = (value) => {
    let range = value;
    this.setState({
      priceRange: range,
    });
  };

  handleChangeOfBulkUploadId(event) {
    // let data = event.target.value
    let inventorybulkxlid = "";
    if (event.target.value === "ALL") {
      inventorybulkxlid = "";
    } else {
      inventorybulkxlid = event.target.value;
    }
    this.setState({
      inventoryxlid: inventorybulkxlid,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // window.open("/myinventory/" + event.target.value)
  }

  handleClose(option) {
    this.setState({ open: false });
    window.open("/create/invoice");
  }

  removeSoldUnsold() {
    this.setState({
      soldStatus: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removePaidStatus() {
    this.setState({
      paidStatus: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeInventoryStatus() {
    this.setState({
      inventoryStatus: "",
      InventoryStatus: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeBulkUploadId() {
    this.setState({
      inventoryxlid: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeInventoryType() {
    this.setState({
      InventoryType: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeImageType = () => {
    this.setState({
      productImage: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  // removeInventoryStatus() {
  //   this.setState({
  //     InventoryStatus: "",
  //     searchEnabled: false,
  //   });
  //   setTimeout(() => {
  //     this.SearchInventoryWatchesdetails();
  //   }, 1000);
  //   // setTimeout(() => {
  //   //   this.setState({ loaded: true });
  //   // }, 1000);
  //   // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
  //   // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  // }

  removetype() {
    this.setState({
      type: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeInventoryCategory() {
    this.setState({
      InventoryCategory: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removebrandname() {
    // var element = document.getElementsByClassName("brandClass")[0];
    // element.classList.remove("MuiInputLabel-shrink");
    //  $('.brandLangend .PrivateNotchedOutline-legendLabelled-87 > span').css('display','none')
    this.props.brandList();
    this.setState({
      modledata: "",
      removedbrndname: "",
      searchEnabled: false,
      brand_id: "",
      brand_description: "",
      BrandName: "",
      loaded: false,
      // band_material: this.state.CaseMaterial,
      // case_size: this.state.CafetchInventoryWatchesseSize,
      // case_material: this.state.case_material,
      page_no: "1",
      limit: "12",
    });
    // setTimeout(() => {
    //   this.setState({ loaded: true });

    // }, 1000);
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
    // let data = {
    //     "brand_id": "",
    // "band_material": this.state.CaseMaterial,
    // "case_size": this.state.CaseSize,
    // "case_material": this.state.case_material,
    //     "page_no": "1",
    //     "limit": "100",
    // }
    // searchApi.productSeach(data, this.state.producId).then((res) => {
    //     this.setState({
    //         browseWatchesInfo: res.data.data
    //     })
    //     if (res.data.success === 1) {
    //         this.setState({
    //             browseWatchesInfo: res.data.data
    //         })
    //     } else {
    //         this.setState({
    //             errorMessage: res.message
    //         })
    //     }

    // })
  }

  handleChangeOfInventoryDetailById = (id, invoiceId) => {
    const { history } = this.props;
    // window.open("/inventory-detail/" + id + "/" + this.state.currentUser.userId);
    if (this.state.userData.userId) {
      this.props.history.push(
        "/inventory-detail/" + id + "/" + this.state.userData.userId,
        {
          inventoryCacheState: { ...this.state }
        }
      );
    }
    localStorage.setItem('invoiceId', invoiceId)
    {
      this.props.inventoryDetailById(id);
    }
  };

  browseWatches() {
    this.setState({
      nonsearchstring: false,
    });
    this.props.fetchSearchBrowseWatches(this.state.searchString);
    // if (this.state.selected.product && this.state.selected.product.model) {
    //   // this.props.history.push(
    //   //   "/browsewatches/" + this.state.selected.product.model
    //   // );
    //   this.props.fetchSearchBrowseWatches(this.props.match.params.productId);
    // }
  }

  onProductChange = _.debounce((e, v, r) => {
    // this.props.onTextInputAdd(text);
    if (v) {
      switch (e) {
        case "product_selected":
          this.setState({
            ...this.state,
            selected: { product: v },
          });
          // this.props.history.push('/browsewatches/' + v.model)
          break;
        case "input_changed":
          this.state.autocomplete.currentInput = v;

          this.state.autocomplete.loading = true;
          this.setState({
            ...this.state,
          });
          if (v.length >= 3 && r == "input") {
            this.props.searchProducts({ searchText: v }, (error, res) => { });
          }
          break;
      }
    } else { }
  }, 600);

  onChangeOfFirstName = (event) => {
    this.setState({
      key: event.target.value,
    });
  };

  autocomplete(evt) {
    let text = evt.target.value;
    this.setState({ searchString: text });
    this.setState({ loading: true });
    this.getAutocomplete(text);
  }

  getAutocomplete = (evt) => {
    // const inventoryList = this.props.InventoryList.data;s
    // this.props.searchProducts({ searchText: evt }, (error, res) => {
    //     this.setState({ searchItems: this.state.autocomplete.data });
    //     this.setState({ loading: false });
    // });
  };

  hanldeKeyup(evt) {
    //if (evt.keyCode === 27) {
    //  this.setState({ searchItems: [] });
    //   return false;
    //  }
  }

  hanldeKeydown(evt) {
    /*
        const { cursor, searchItems } = this.state;
        // arrow up/down button should select next/previous list element
        if (evt.keyCode === 38 && cursor > 0) {
          this.setState((prevState) => ({
            cursor: prevState.cursor - 1,
          }));
        } else if (evt.keyCode === 40 && cursor < searchItems.length - 1) {
          this.setState((prevState) => ({
            cursor: prevState.cursor + 1,
          }));
        }
        if (evt.keyCode === 13) {
          let currentItem = searchItems[cursor];
          if (currentItem !== undefined) {
            const { name, code, rate, unit } = currentItem;
            this.setState({ item: { name, code, rate, unit }, searchItems: [] });
          }
        }
        if (evt.keyCode === 8) {
          this.setState({ item: { name: "", code: "", rate: "", unit: "" } });
        }*/
  }

  selectItem(id) {
    const { searchItems } = this.state;
    let selectedItem = searchItems.find((item) => item.model === id);
    // const { code, name, unit, rate } = selectedItem;
    //  this.setState({ item: {  name, unit, rate } });
    this.setState({ searchString: selectedItem.searchattr });
    this.setState({ searchStringdetails: selectedItem.searchattr });
    this.setState({ autocomplete: { data: [] } });
    // this.props.fetchSearchBrowseWatches(id);
  }

  handleListKeydown(evt) { }

  handleChange(evt) {
    this.setState({ item: { [evt.target.name]: evt.target.value } });
  }

  downloadInventory(type) {
    const userId = this.state.userData && this.state.userData.userId;
    const id = userId ? userId : null;
    this.setState({ downloadloading: true });
    let keySearch = this.state.key;
    let searchData = "";
    if (keySearch) {
      searchData = keySearch.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
      searchData = searchData.replaceAll("-", "");
      searchData = searchData.trim()
    }
    // let data = {
    //   brand_id: this.state.brand_id,
    //   brand_name: this.state.brand_description,
    //   key: searchData,
    //   sold: this.state.soldStatus === "" ? "ALL" : this.state.soldStatus,
    //   // inventory_status: this.state.inventoryStatus,
    //   inventoryxlid: this.state.inventoryxlid,
    //   inventory_type: this.state.InventoryType,
    //   inventory_status: this.state.InventoryStatus,
    //   purchasedate:
    //     this.state.purchasedate === 0
    //       ? "ALL"
    //       : this.state.purchasedate > 180
    //         ? "UNKNOWN"
    //         : this.state.purchasedate,
    //   userid: id,
    //   accountid: this.state.accountId ? this.state.accountId : null,
    //   type: this.state.type === "" ? "ALL" : this.state.type,
    //   category: this.state.InventoryCategory,
    //   fileType: type
    // };
    let data = {
      accountid: this.state.accountId ? this.state.accountId : null,
      userid: this.state.userData.userId,
      brand_id: this.state.brand_id,
      brand_name: this.state.brand_description,
      key: searchData,
      sold: this.state.soldStatus === "" ? "ALL" : this.state.soldStatus,
      invoicestatus: this.state.paidStatus,
      dial_color: this.state.DialColor,
      inventoryxlid: this.state.inventoryxlid,
      inventory_type: this.state.InventoryType,
      inventory_status: this.state.InventoryStatus,
      purchace_date:
        this.state.purchasedate === 0
          ? "ALL"
          : this.state.purchasedate > 180
            ? "UNKNOWN"
            : this.state.purchasedate,
      type: this.state.type,
      category: this.state.InventoryCategory,
      product_image: this.state.productImage,
      fileType: type
    };
    if (data.type === "ALL") {
      delete data.type
    }
    if (data.category === "ALL") {
      delete data.category
    }
    if (data.sold === "") {
      data.sold = "ALL"
    }
    data['priceRange'] = {
      min: this.state.priceRange[0],
      max: this.state.priceRange[1] > 100000 ? null : this.state.priceRange[1]
    }
    if (data.priceRange.min == 0 && data.priceRange.max == null) {
      delete data.priceRange
    }
    // const inventoryDetails = this.props.SearchInventoryWatches(data, this.state.currentUser.userId);
    searchinventry.downloadInventory(data, id).then((res) => {
      if (res && res.status === 0) {
        toast("No Inventories for download.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      this.setState({ downloadloading: false });
    });
  }

  handleSwitch = (row, event) => {
    const id = row.inventoryid;
    const checkstaus = event.target.checked;
    this.setState({
      rowDetails: row
    })
    if (checkstaus === true) {
      this.setState({
        soldbtn: true,
        disable: true,
      });
      localStorage.setItem("sold_status", "1");
      localStorage.setItem("inventory_id", id);
      this.checkboxval({ id: id, sold: true });
      this.setState({ soldbtn: true });
    } else {
      this.setState({ soldbtn: false });
      localStorage.setItem("sold_status", "0");
      localStorage.setItem("inventory_id", id);
      this.checkboxval({ id: id, sold: false });
      this.setState({ soldbtn: false });
    }
    this.setState({
      openReasonChange: true
    })
  }

  handleCloseOfReasonChange() {
    const id = this.state.rowDetails.inventoryid;
    let sold = this.state.rowDetails.sold;
    if (sold == 0) {
      this.checkboxval({ id: id, sold: true });

    } else {
      this.checkboxval({ id: id, sold: false });
    }
    this.setState({
      openReasonChange: false,
    })
  }

  copyToClipboard = async (token = null) => {
    const userDetails = window.sessionStorage.getItem('userDetails') ? JSON.parse(window.sessionStorage.getItem('userDetails')) : null;
    let companyName = userDetails?.accountName?.replace(/[\s.]/g, '');
    const baseURL = `${constants.SHARE_INVENTORY}dealerfeed/${companyName}`;
    const copyText = token ? `${baseURL}?token=${token}` : baseURL;
    alert(copyText)
    try {
      await copy(copyText);
      toast.success("Copied to Clipboard");
    }
    catch {
      toast.error("Not copied");
    }
    //const isCopy=await navigator.clipboard.writeText(copyText)
    // if (isCopy) {
    //   toast.success("Copied to Clipboard");
    // }
  };

  changeRangeName = (reason) => {
    // const id = row.inventoryid;
    // const { productid, serialno } = row;
    const id = this.state.rowDetails.inventoryid;
    const { productid, serialno, invoiceid } = this.state.rowDetails;
    let data = {
      inventory_id: localStorage.getItem("inventory_id"),
      sold_status: localStorage.getItem("sold_status"),
      productId: productid,
      serialNumer: serialno,
      listed_on: this.state.listed_on,
      sold_unsold_reason: reason,
      invoiceid: invoiceid ? invoiceid : null
    };
    searchinventry.productsold(data).then((res) => {
      if (res.data.success === 1) {
        this.setState({
          openReasonChange: false
        })
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.SearchInventoryWatchesdetails();
        localStorage.removeItem("inventory_id");
        localStorage.removeItem("sold_status");

      } else {
        this.setState({
          openReasonChange: false
        })
        this.handleCloseOfReasonChange();
        localStorage.removeItem("inventory_id");
        localStorage.removeItem("sold_status");
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    });
  };

  // copy = () => {
  //   const el = document.createElement(
  //     "https://api.watchops.com/inventories/{this.props.currentUser.userId}/c24-importer.xml"
  //   );
  //   el.value = window.location.href;
  //   document.body.appendChild(el);
  //   el.select();
  //   document.execCommand("copy");
  //   document.body.removeChild(el);
  //   this.setState({
  //     setCopied: true,
  //   });
  // };

  handleChangeOfSoldUnSold(event) {
    let brand = event.target.value;
    this.setState({ soldStatus: brand });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }

  handleChangeOfPaidStatus(event) {
    let paid = event.target.value;
    this.setState({
      paidStatus: paid,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }

  handleNotforSaleCheck = (event) => {
    this.setState({
      notforSale: event.target.checked,
    })
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }

  handleChangeOfDialColor(event) {
    let dialvalue = event.target.value;
    this.setState({
      DialColor: dialvalue,
    });
  }

  handleEnterEvent(event) {
    if (event.key == 'Enter') {
      this.SearchInventoryWatchesdetails();
    }
  }

  handleChangeOfInventoryStatus(event) {
    let status = event.target.value;
    this.setState({
      inventoryStatus: status,
    });

    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }

  handleChangePage = (event, newpage) => {
    this.setState({ page: newpage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  handleClearButton = () => {
    this.setState({
      key: "",
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  };

  handleClearDialbutton = () => {
    this.setState({
      DialColor: "",
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  };

  endAdornment = () => {
    const values = this.state.key;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              this.handleClearButton();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };

  dialColorAdornment = () => {
    const values = this.state.DialColor;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              this.handleClearDialbutton();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };

  calculateTotalCostforInventory = (inventoryList) => {
    const total = inventoryList?.reduce((total, item) => {
      const itemCost = item?.total_cost ? Number(item.total_cost) : 0;
      return total + (isNaN(itemCost) ? 0 : itemCost);
    }, 0);
    return total.toLocaleString('en-US');
  }

  handleMouseEnter = (id) => {
    this.setState({ hoveredCard: id });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredCard: null });
  };

  handleCheckboxChange = (id) => {
    const { noOfItemsInventory } = this.state;
    this.setState((prevState) => {
      const { selectedItems } = prevState;
      const newSelectedItems = selectedItems.includes(id)
        ? selectedItems.filter(item => item !== id)
        : [...selectedItems, id];
      const selectAll = newSelectedItems.length == noOfItemsInventory;
      return {
        selectedItems: newSelectedItems,
        selectAll: selectAll
      };
    });
  };


  handleSelectAllChange = (event, inventoryList) => {
    if (event.target.checked) {
      this.setState({ selectedItems: inventoryList.map(item => item.inventoryid), selectAll: true });
    } else {
      this.setState({ selectedItems: [], selectAll: false });
    }
  };

  handleOTP = () => {
    let email = this.state.userData?.email;
    if (!email) {
      toast("Email not found, Please login again.", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    this.setState({ disabled: true });
    let data = {
      'to': email ?? null,
      'url': 'email-otp/send'
    };
    this.props.recoverPassword(data, (error, res) => {
      if (error) {
        showErrorToast(error);
        this.setState({ disabled: false });
      } else {
        if (res.success == 1) {
          showSuccessToast("OTP Sent");
          this.setState({ openDeleteModal: false, openOtpModel: true, disabled: false });
        }
        else {
          showErrorToast(res.message);
          this.setState({ disabled: false });
        }
      }
    })
  }

  onResendOtp = () => {
    let email = this.state.userData?.email;
    if (!email) {
      toast("Email not found, Please login again.", {
        autoClose: 5000,
        type: toast.TYPE.ERROR,
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }
    let data = {
      'to': email ?? null,
      'url': 'email-otp/send'
    };
    this.props.recoverPassword(data, (error, res) => {
      if (error) {
        showErrorToast(error);
      } else {
        if (res.success == 1) {
          document.getElementById("otp_form").reset();
          this.setState({ showing: false });
          showSuccessToast("OTP Sent");
        } else {
          showErrorToast(res.message);
          this.setState({ disabled: false });
        }
      }
    })
  }

  onOtpChange = (event) => {
    let otpVal = validator.isNumeric(event.target.value);
    if (!otpVal) {
      this.setState({
        ...this.state,
        otpErrorText: "Enter Otp",
        isValid: false
      });
    } else {
      this.setState({
        ...this.state,
        otp: event.target.value,
        otpErrorText: "",
        isValid: true
      });
    }
  }

  validateInput = () => {
    let otpErrorText = '';
    let isValid = true;
    if (this.state.otp.length === 0) {
      otpErrorText = 'Enter OTP';
      isValid = false;
    }
    this.setState({
      ...this.state,
      otpErrorText
    });
    return isValid;
  }

  handleVerifyOTP = (inventoryList) => {
    let email = this.state.userData?.email;
    if (this.validateInput()) {
      this.setState({ disabled: true });
      let data = {
        'otp': this.state.otp,
        'to': email ?? null,
        'url': 'email-otp/verify'
      };
      this.props.verifyOtp(data, (error, res) => {
        if (error) {
          showErrorToast("Invalid OTP");
          this.setState({ disabled: false });
        } else {
          if (res.success) {
            this.handleInventoryDelete(inventoryList);
          } else {
            showErrorToast("Invalid OTP");
            this.setState({ disabled: false });
          }
        }
      })
    }
  }

  handleInventoryDelete = (inventoryList) => {
    let { selectedItems, userData, accountId, isDeleteAll, key,
      brand_id, brand_description, paidStatus, soldStatus, DialColor, inventoryxlid,
      InventoryType, InventoryStatus, purchasedate, type, InventoryCategory } = this.state;
    let data = {};
    if (isDeleteAll) {
      data = {
        accountId: accountId,
        inventoryId: null,
        delete: "ALL",
      };
    }
    else if (selectedItems.length === inventoryList.length) {
      let searchData = "";
      if (key) {
        searchData = key.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
        searchData = searchData.replaceAll("-", "");
        searchData = searchData.trim()
      }
      data = {
        accountId: accountId,
        filters: {
          brand_id: brand_id,
          brand_name: brand_description,
          key: searchData,
          invoicestatus: paidStatus,
          sold: soldStatus,
          dial_color: DialColor,
          inventoryxlid: inventoryxlid,
          inventory_type: InventoryType,
          inventory_status: InventoryStatus,
          purchace_date:
            purchasedate === 0
              ? "ALL"
              : purchasedate > 180
                ? "UNKNOWN"
                : purchasedate,
          type: type,
          category: InventoryCategory
        },
      };
    }
    else {
      data = {
        accountId: accountId,
        inventoryId: selectedItems,
        delete: "",
      };
    }
    deleteInventories(data, (error, res) => {
      if (error) {
        showErrorToast(error);
        this.setState({ disabled: false });
      } else {
        if (res.success == 1) {
          this.SearchInventoryWatchesdetails();
          showSuccessToast("Deleted Successfully");
          this.setState({ openOtpModel: false, disabled: false, isDeleteAll: false });
        }
        else {
          showErrorToast(res.message);
          this.setState({ disabled: false });
        }
      }
    })
  }

  sanitizeKey = (key) => {
    if (!key) return "";
    let sanitizedKey = key.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
    return sanitizedKey.replaceAll("-", "").trim();
  };

  handleShareInventory = () => {
    let { selectedItems, userData, accountId, selectAll, key,
      brand_id, brand_description, paidStatus, soldStatus, DialColor, inventoryxlid,
      InventoryType, InventoryStatus, purchasedate, type, InventoryCategory } = this.state;
    this.setState({ disabled: true });
    const data = selectAll
      ? {
        accountId: accountId,
        userId: userData?.userId,
        brand_id: brand_id,
        brand_name: brand_description,
        key: this.sanitizeKey(key),
        invoicestatus: paidStatus,
        sold: soldStatus,
        dial_color: DialColor,
        inventoryxlid: inventoryxlid,
        inventory_type: InventoryType,
        inventory_status: InventoryStatus,
        purchace_date: purchasedate === 0 ? "ALL" : purchasedate > 180 ? "UNKNOWN" : purchasedate,
        type: type,
        category: InventoryCategory
      }
      : {
        accountId: accountId,
        inventoryId: selectedItems,
        userId: userData?.userId
      };
    shareInventories(data, (error, res) => {
      if (error) {
        showErrorToast(error);
        this.setState({ disabled: false });
      } else {
        if (res.success == 1) {
          this.copyToClipboard(res?.data);
          this.setState({ openShareModal: false, disabled: false });
        }
        else {
          showErrorToast(res.message);
          this.setState({ disabled: false });
        }
      }
    })
  }

  inventoryrender() {
    const { classes } = this.props;
    const { hoveredCard, selectedItems, totalCostforInventory, noOfItemsInventory, selectAll,
      userData, soldStatus
    } = this.state;
    let inventoryList = [];
    // if (
    //   this.state.searchEnabled &&
    //   (this.state.key !== "" ||
    //     this.state.brand_id !== "" ||
    //     this.state.soldStatus !== "" ||
    //     this.state.inventoryxlid !== "" ||
    //     this.state.InventoryType !== "" ||
    //     this.state.InventoryStatus !== ""
    //   )
    // )
    if (this.state.updateIntiate) {
      inventoryList = this.state.updatedInvenentryList;
    }
    else {
      inventoryList = this.props.InventoryList.data;
    }
    const brandInfo = this.props.brandInfo.data;
    // const dialColorInfo = this.props.dialColorInfo.data;
    const inventoryXlIdInfo = this.props.inventoryXlIdInfo.data;
    // const totalCostforInventory = this.calculateTotalCostforInventory(inventoryList);
    return (
      <div className="space-bottom-30 someextrahight" style={{ marginTop: '20px' }}>
        {/* <Loader loaded={this.state.loaded}> */}
        <Container>
          {/* <div style={{ marginBottom: "20px" ,wordBreak:"break-word"}}>
                        <a href="">
                        {inventoryList && inventoryList.length > 0 ? <Alert severity="info" className="info">
           
           
                        Your Chrono24 xml url is <strong> URL(https://api.watchops.com/inventories/{this.props.currentUser.userId}/c24-importer.xml)</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Tooltip title="Copy">
                          <IconButton aria-label="copy" onClick={this.copy}>
                             <FileCopyIcon />
                          </IconButton>
                        </Tooltip>
                        </Alert> : ""}
                       
                        </a>
                    </div> */}
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Typography type="title" color="inherit">
                <h1><b>MY INVENTORY</b></h1>
              </Typography>
            </Grid>
            {!isActionAllowed(this.state.accessData, 'download_inventory') &&
              <Grid item xs={12} md={4}></Grid>
            }
            <Grid item xs={6} md={this.props?.InventoryList?.data?.length > 0 && this.props?.InventoryList?.data[0]?.dealerpublish ? 2 : 4} style={{ margin: "auto" }}>
              <Button
                id="demo-customized-button"
                // aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={() => this.setState({ filterEnable: !this.state.filterEnable })}
                className={
                  "text-capitalize primary-bg color-white background"
                }
                style={{ float: "right" }}
                endIcon={this.state.filterEnable ? <FilterAltIcon /> : <FilterAltOffIcon />}
              >
                &nbsp;Filter
              </Button>
            </Grid>
            {this.props?.InventoryList?.data?.length > 0 && this.props?.InventoryList?.data[0]?.dealerpublish ?
              <Grid item xs={6} md={2} style={{ margin: "auto" }}>
                <Button
                  id="demo-customized-button"
                  // aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup="true"
                  // aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={() => this.copyToClipboard()}
                  className={
                    "text-capitalize primary-bg color-white"
                  }
                  style={{ float: "right" }}
                // endIcon={<KeyboardArrowDownOutlined />}
                >
                  <Share />&nbsp;Share Inventory
                </Button>
              </Grid>
              : null}
            {isActionAllowed(this.state.accessData, 'download_inventory') && (
              <>
                <Grid item xs={6} md={2} style={{ margin: "auto" }}>
                  <CustomizedUploadMenus downloadInventory={this.downloadInventory} history={this.props?.history} />
                </Grid>
                <Grid item xs={6} md={2} style={{ margin: "auto" }}>
                  <CustomizedMenus downloadInventory={this.downloadInventory} />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={12} md={3}>
              <span
                onClick={this.removeSoldUnsold}
                style={{ float: "right" }}
              >
                <Icon
                  className={
                    this.state.soldStatus ? "fa fa-times-circle" : ""
                  }
                />
              </span>
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                >
                  Sold / Unsold
                </InputLabel>
                <Select
                  style={{ color: "black" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={this.handleChangeOfSoldUnSold}
                  label="Sold / Unsold"
                  value={this.state.soldStatus}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="1">Sold</MenuItem>
                  <MenuItem value="0">Unsold</MenuItem>
                  <MenuItem value="Void">Void</MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                      variant="h6"
                      className="color-383838"
                      style={{ fontSize: "15px" }}
                    >
                      {" "}
                      {this.state.soldStatus === "1" ? "Sold" : ""}{" "}
                      {this.state.soldStatus === "0" ? "Unsold" : " "}{" "}
                    </Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <span
                onClick={this.removePaidStatus}
                style={{ float: "right" }}
              >
                <Icon
                  className={
                    this.state.paidStatus ? "fa fa-times-circle" : ""
                  }
                />
              </span>
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                >
                  Paid Status
                </InputLabel>
                <Select
                  style={{ color: "black" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={this.handleChangeOfPaidStatus}
                  label="Paid Status"
                  value={this.state.paidStatus}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                  <MenuItem value="notPaid">Not Paid</MenuItem>
                </Select>
              </FormControl>
              {/* <Typography
                      variant="h6"
                      className="color-383838"
                      style={{ fontSize: "15px" }}
                    >
                      {" "}
                      {this.state.soldStatus === "1" ? "Sold" : ""}{" "}
                      {this.state.soldStatus === "0" ? "Unsold" : " "}{" "}
                    </Typography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <span
                onClick={this.removebrandname}
                style={{ float: "right" }}
              >
                <Icon
                  className={
                    this.state.modledata ? "fa fa-times-circle" : ""
                  }
                />
              </span>
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                >
                  Brand
                </InputLabel>
                <Select
                  style={{ color: "black" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={this.handleChangeOfBrandByBrandName}
                  label="Brand"
                >
                  <MenuItem value="ALL">All</MenuItem>
                  {brandInfo &&
                    brandInfo.map((row) => (
                      <MenuItem value={row}>{row.brandname}</MenuItem>
                    ))}
                </Select>
                {/* <Typography
                          variant="h6"
                          className="color-383838"
                          style={{ fontSize: "15px" }}
                        >
                          {this.state.modledata
                            ? this.state.modledata
                            : this.state.removedbrndname}{" "}
                        </Typography> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <span
                onClick={this.removeBulkUploadId}
                style={{ float: "right" }}
              >
                <Icon
                  className={
                    this.state.inventoryxlid ? "fa fa-times-circle" : ""
                  }
                />
              </span>
              <FormControl variant="outlined" className="formControl">
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className="font-14"
                >
                  Bulk Upload Id
                </InputLabel>
                <Select
                  style={{ color: "black" }}
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  onChange={this.handleChangeOfBulkUploadId}
                  label="Bulk Upload Id"
                  value={"" || this.state.inventoryxlid}
                >
                  <MenuItem value="ALL">All</MenuItem>
                  {inventoryXlIdInfo &&
                    inventoryXlIdInfo.map((row) => (
                      <MenuItem
                        key={row.inventoryxlid}
                        value={row.inventoryxlid}
                      >
                        {row.inventoryxlid}
                      </MenuItem>
                    ))}
                </Select>
                {/* <Typography variant="h6" className="color-383838" style={{ fontSize: "15px" }}>{this.state.inventoryxlid ? this.state.inventoryxlid : this.state.removeBulkUploadId}   </Typography> */}
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className="form-width-99"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.SearchInventoryWatchesdetails();
                }}
              >
                <div style={{ marginTop: "3%", marginBottom: "10px" }}>
                  <Paper
                    // component="form"
                    style={{ height: "50px", display: "flex" }}
                  >
                    <InputBase
                      fullWidth
                      sx={{ ml: 3, flex: 1 }}
                      inputProps={{
                        "aria-label": "Enter Dial Color",
                      }}
                      placeholder="Enter Dial Color"
                      // defaultValue={this.state.key}
                      onChange={this.handleChangeOfDialColor}
                      onKeyPress={this.handleEnterEvent}
                      value={this.state.DialColor}
                      endAdornment={this.dialColorAdornment()}
                    />
                  </Paper>
                </div>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl
                className="form-width-99"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.SearchInventoryWatchesdetails();
                }}
              >
                <div style={{ marginTop: "3%", marginBottom: "10px" }}>
                  <Paper
                    // component="form"
                    style={{ height: "50px", display: "flex" }}
                  >
                    <InputBase
                      fullWidth
                      sx={{ ml: 3, flex: 1 }}
                      inputProps={{
                        "aria-label": "Enter Serial no / Model no / Notes / Inventory Id / Inventory Title",
                      }}
                      placeholder="Enter Serial no / Model no / Notes / Inventory Id / Inventory Title"
                      // defaultValue={this.state.key}
                      onChange={this.onChangeOfFirstName}
                      onKeyPress={this.handleEnterEvent}
                      // onChange={onSearchClick}
                      //  onChange={(e) => {
                      //   onSearchClick(e);
                      //  }}
                      value={this.state.key}
                      endAdornment={this.endAdornment()}
                    />
                  </Paper>
                </div>
              </FormControl>
            </Grid>
            {/* <Grid item xs={6} md={2}>
                    <FormControl className="form-width-70 margin-t-b-20 margin-p-b-20" style={{ margin: 20 }}>

                      <FormLabel id="demo-radio-buttons-group-label" style={{ color: '#424242' }}>SALE STATUS</FormLabel>
                      <div  >

                        <FormControlLabel
                          control={
                            <Checkbox
                              name="checkedB1"
                              value={this.state.notforSale}
                              checked={this.state.notforSale}
                              className="float-right"
                              onChange={this.handleNotforSaleCheck}
                            />
                          }
                          label="Not for sale"
                        />

                      </div>
                    </FormControl>
                  </Grid> */}
            {this.state.filterEnable &&
              (
                <>
                  <Grid item xs={12} sm={12} md={3}>
                    <span
                      onClick={this.removetype}
                      style={{ float: "right" }}
                    >
                      <Icon
                        className={
                          this.state.type ? "fa fa-times-circle" : ""
                        }
                      />
                    </span>
                    <FormControl variant="outlined" className="formControl">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className="font-14"
                      >
                        Type
                      </InputLabel>
                      <Select
                        style={{ color: "black" }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        onChange={(e) => {
                          if (e.target.value !== 'misc_product') {
                            this.setState({ type: e.target.value, InventoryCategory: "" });
                          } else {
                            this.setState({ type: e.target.value });
                          }
                          setTimeout(() => {
                            this.SearchInventoryWatchesdetails();
                          }, 1000);
                        }
                        }
                        label="Brand"
                        value={this.state.type}
                      >
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="standand_and_misc">Standard and Misc Watch</MenuItem>
                        <MenuItem value="standard_watch">Standard Watch</MenuItem>
                        <MenuItem value="misc_watch">Misc Watch</MenuItem>
                        <MenuItem value="misc_product">Misc Product</MenuItem>
                      </Select>
                    </FormControl>
                    {/* <Typography
                      variant="h6"
                      className="color-383838"
                      style={{ fontSize: "15px" }}
                    >
                      {this.state.type}
                    </Typography> */}
                  </Grid>

                  <Grid item xs={12} sm={12} md={3}>
                    <span
                      onClick={this.removeInventoryCategory}
                      style={{ float: "right" }}
                    >
                      <Icon
                        className={
                          this.state.InventoryCategory ? "fa fa-times-circle" : ""
                        }
                      />
                    </span>
                    <FormControl variant="outlined" className="formControl">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className="font-14"
                      >
                        Category
                      </InputLabel>
                      <Select
                        style={{ color: this.state.type !== 'misc_product' ? "#bbb" : "black", background: this.state.type !== 'misc_product' ? "#ccc" : "#fff" }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Inventory Category"
                        defaultValue={this.state.InventoryCategory}
                        value={this.state.InventoryCategory}
                        disabled={this.state.type !== 'misc_product'}
                        onChange={(e) => {
                          this.setState({ InventoryCategory: e.target.value });
                          setTimeout(() => {
                            this.SearchInventoryWatchesdetails();
                          }, 1000);
                        }}
                      >
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="watch related accessories">Watch Accessories</MenuItem>
                        <MenuItem value="apparel">Apparel</MenuItem>
                        <MenuItem value="watch parts">Watch Part (s)</MenuItem>
                        <MenuItem value="jewelry">Jewelry</MenuItem>
                        <MenuItem value="stones">Gem (s)</MenuItem>
                        <MenuItem value="others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <span
                      onClick={this.removeInventoryStatus}
                      style={{ float: "right" }}
                    >
                      <Icon
                        className={
                          this.state.InventoryStatus ? "fa fa-times-circle" : ""
                        }
                      />
                    </span>
                    <FormControl variant="outlined" className="formControl">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className="font-14"
                      >
                        Inventory Status
                      </InputLabel>
                      <Select
                        style={{ color: "black" }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Inventory Status"
                        defaultValue={this.state.InventoryStatus}
                        value={this.state.InventoryStatus}
                        onChange={(e) => {
                          this.setState({ InventoryStatus: e.target.value });
                          setTimeout(() => {
                            this.SearchInventoryWatchesdetails();
                          }, 1000);
                        }}
                      >
                        <MenuItem value="1">Inhand</MenuItem>
                        <MenuItem value="2">Incoming</MenuItem>
                        <MenuItem value="3">Out on Memo</MenuItem>
                        <MenuItem value="4">On Hold / Reserved</MenuItem>
                        <MenuItem value="5">Available to Order</MenuItem>
                        <MenuItem value="6">Needs Service / Polish</MenuItem>
                        <MenuItem value="7">At Service</MenuItem>
                        <MenuItem value="8">Need to Ship</MenuItem>
                        <MenuItem value="9">Shipped waiting on payment</MenuItem>
                        <MenuItem value="10">Sold & Cashed Out</MenuItem>
                        <MenuItem value="11">Intake Complete</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <span
                      onClick={this.removeInventoryType}
                      style={{ float: "right" }}
                    >
                      <Icon
                        className={
                          this.state.InventoryType ? "fa fa-times-circle" : ""
                        }
                      />
                    </span>

                    <FormControl variant="outlined" className="formControl">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className="font-14"
                      >
                        Inventory Type
                      </InputLabel>
                      <Select
                        style={{ color: "black" }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Inventory Type"
                        defaultValue={this.state.InventoryType}
                        value={this.state.InventoryType}
                        onChange={(e) => {
                          this.setState({ InventoryType: e.target.value });
                          setTimeout(() => {
                            this.SearchInventoryWatchesdetails();
                          }, 1000);
                        }}
                      >
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="1">Regular Inventory</MenuItem>
                        <MenuItem value="2">Memo Inventory</MenuItem>
                        <MenuItem value="3">Partnership Inventory</MenuItem>
                        <MenuItem value="4">Trade Inventory</MenuItem>
                        <MenuItem value="NULL">
                          <i>Not Assigned </i>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="fieldset"
                      borderColor="transparent"
                      className="padding-0 "
                      // style={{ margin: "20px 0px" }}
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel
                        htmlFor="component-simple"
                        style={{ color: "#424242", marginBottom: "45px" }}
                      >
                        Inventory Age
                      </InputLabel>
                      <FormControl className="form-width-100 sliderclass">
                        <MuiSlider
                          value={this.state.purchasedate}
                          aria-labelledby="discrete-slider-always"
                          getAriaValueText={this.valueLabelFormat}
                          valueLabelFormat={this.valueLabelFormat}
                          valueLabelDisplay="on"
                          step={1}
                          marks={followersMarks1}
                          min={0}
                          max={220}
                          onChange={(event, value) => {
                            this.inventoryPurchaseDateInterval(value);
                          }}
                          onMouseUp={(event) => {
                            setTimeout(() => {
                              this.SearchInventoryWatchesdetails();
                            }, 1000);
                          }}
                          className="MuiOutlinedInput"
                          ariaLabelledBy="non-linear-slider"
                        />
                        <FormHelperText className="color-BCBCBC">
                          (Ex: 15 days, Purchase date is greater than or equal
                          to 15 days )
                        </FormHelperText>
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="fieldset"
                      borderColor="transparent"
                      className="padding-0 "
                      // style={{ margin: "20px 0px" }}
                      style={{ marginTop: "20px" }}
                    >
                      <InputLabel
                        htmlFor="component-simple"
                        style={{ color: "#424242", marginBottom: "45px" }}
                      >
                        End User Target Price
                      </InputLabel>
                      <FormControl className="form-width-100 sliderclass">
                        {/* <Typography
                style={{
                  textAlign: "right",
                  marginTop: "-20px",
                  visibility: "hidden",
                }}
              >
                {" "}
                {2}
              </Typography> */}

                        <MuiSlider
                          value={this.state.priceRange}
                          aria-labelledby="discrete-slider-always"
                          getAriaValueText={this.valuePriceRangeFormat}
                          // valueLabelFormat={this.valuePriceRangeFormat}
                          valueLabelDisplay="on"
                          step={5}
                          marks={PriceRangeObj}
                          min={0}
                          max={100001}
                          onChange={(event, value) => {
                            this.inventoryPriceRangeInterval(value);
                          }}
                          onMouseUp={(event) => {
                            setTimeout(() => {
                              this.SearchInventoryWatchesdetails();
                            }, 1000);
                          }}
                          // classes={{valueLabelLabel}}
                          //className="MuiOutlinedInput"
                          ariaLabelledBy="non-linear-slider"
                        />
                      </FormControl>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} style={{ marginBottom: "15px" }}>
                    <span
                      onClick={this.removeImageType}
                      style={{ float: "right" }}
                    >
                      <Icon
                        className={
                          this.state.productImage ? "fa fa-times-circle" : ""
                        }
                      />
                    </span>
                    <FormControl variant="outlined" className="formControl">
                      <InputLabel
                        id="demo-simple-select-outlined-label"
                        className="font-14"
                      >
                        Image Type
                      </InputLabel>
                      <Select
                        style={{ color: "black" }}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        label="Inventory Type"
                        value={this.state.productImage}
                        onChange={(e) => {
                          this.setState({ productImage: e.target.value });
                          setTimeout(() => {
                            this.SearchInventoryWatchesdetails();
                          }, 1000);
                        }}
                      >
                        <MenuItem value="ALL">All</MenuItem>
                        <MenuItem value="with_image">With Image</MenuItem>
                        <MenuItem value="without_image">Without Image</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={9}></Grid>
                </>
              )}
            {this.state.loaded ?
              <>
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                    <Typography variant="h6"># of Items : </Typography>&nbsp;
                    <Typography variant="h6"> {noOfItemsInventory ?? 0} </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  {(userData?.roleId == "2" || userData?.roleId == "3" || userData?.roleId == "6") ?
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent={{ xs: "flex-start", md: "end" }} mb={2}>
                      <Typography variant="h6">Total Cost : </Typography>&nbsp;
                      <Typography variant="h6"> $ {totalCostforInventory?.toLocaleString('en-US') ?? 0} </Typography>
                    </Box>
                    : null}
                </Grid>
              </>
              : null}

            {inventoryList && inventoryList.length > 0 && this.state.loaded &&
              (userData?.roleId == 2 || userData?.roleId == 3 || userData?.roleId == 6
              ) && soldStatus === "0" && (
                <>
                  <Grid item xs={12} md={6} style={{ padding: "10px 0", display: "flex", alignItems: "center" }}>
                    <Checkbox style={{ padding: "0 5px" }} checked={selectAll} onChange={(e) => this.handleSelectAllChange(e, inventoryList)} />
                    <Typography variant="p" className="text-capitalize" style={{ fontWeight: 600 }}>Select All {`(# Items selected : ${(selectAll ? noOfItemsInventory : selectedItems.length) ?? 0})`}</Typography>
                  </Grid>
                  {console.log("sss", this.props?.InventoryList?.data)}
                  {selectedItems && selectedItems.length > 0 &&
                    <Grid item xs={12} md={6} style={{ padding: "0 10px", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "10px" }}>
                      {this.props?.InventoryList?.data?.length > 0 && soldStatus === "0" && userData?.accountName &&
                        (
                          <Button
                            id="demo-customized-button"
                            aria-haspopup="true"
                            variant="contained"
                            disableElevation
                            className="text-capitalize primary-bg color-white"
                            onClick={() => { this.setState({ openShareModal: true }) }}
                          >
                            <Share />&nbsp;Share
                          </Button>
                        )}
                      <Button
                        id="demo-customized-button"
                        aria-haspopup="true"
                        variant="contained"
                        disableElevation
                        className="text-capitalize primary-bg color-white"
                        onClick={() => { this.setState({ openDeleteModal: true }) }}
                      >
                        Delete
                      </Button>
                      {/* <Button
                        id="demo-customized-button"
                        aria-haspopup="true"
                        variant="contained"
                        disableElevation
                        className="text-capitalize primary-bg color-white"
                        onClick={() => { this.setState({ openDeleteModal: true, isDeleteAll: true }) }}
                      >
                        Master Delete
                      </Button> */}
                    </Grid>
                  }
                </>
              )}
          </Grid>
          <br />
          {!this.state.loaded ? (
            <div className="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={!this.state.loaded}
              />
            </div>
          ) :
            <InfiniteScroll
              style={{ overflow: "hidden" }}
              dataLength={inventoryList.length ?? 0}
              next={() => this.fetchMoreData(inventoryList)}
              hasMore={this.state.noItemFound ? false : true}
              loader={inventoryList.length > 0 ?
                <div style={{ margin: "25px auto", width: "100px" }}>
                  <Loader
                    type="Puff"
                    color="#9F7C50"
                    height={50}
                    width={50}
                  />
                  <div style={{ marginLeft: "-10px" }}>
                    <h4 className="color-p">Loading....</h4>
                  </div>
                </div> : null
              }
            // endMessage={
            //   <p style={{ textAlign: "center" }}>
            //     <b>Yay! You have seen it all</b>
            //   </p>
            // }
            >
              {/* {this.state.loaded && ( */}
              <Grid container spacing={1}>
                {this.state.inventoryData &&
                  this.state.inventoryData.length > 0 &&
                  this.state.inventoryxlid !== "" && (
                    <div style={{ margin: "auto", width: "100%" }}>
                      <Container style={{ marginBottom: "30px" }}>
                        <MaterialTable
                          title={`Bulk Upload Summary`}
                          data={this.state.inventoryData}
                          columns={[
                            { field: "status", title: "STATUS" },
                            {
                              field: "filePath",
                              title: "DOWNLOAD FILE",
                              render: (rowData) => (
                                <Link
                                  href={`${rowData.filePath}`}
                                  target="_blank"
                                >
                                  {rowData.filePath}
                                </Link>
                              ),
                            },
                          ]}
                        />
                      </Container>
                    </div>
                  )}
                {/* {inventoryList &&
                inventoryList.length === 0 &&
                !this.state.loaded ? (
                <Container style={{ marginBottom: "30px" }}>
                  <Typography
                    variant="body2"
                    className="textSecondary color-292C2F"
                    component="p"
                    align="center"
                  >
                    <b>No Watches Added Here!</b>
                  </Typography>
                </Container>
              ) : (
                ""
              )} */}
                {/* {inventoryList && inventoryList
                   .slice(
                     this.state.page * this.state.rowsPerPage,
                     this.state.page * this.state.rowsPerPage +
                     this.state.rowsPerPage
                   ) */}
                {inventoryList && inventoryList.length > 0 && inventoryList.map((row) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      className="inventory-card-container"
                      key={row.inventoryid}
                      onMouseEnter={(userData?.roleId == 2 || userData?.roleId == 3
                        || userData?.roleId == 6) && soldStatus === "0"
                        ? () => this.handleMouseEnter(row.inventoryid) : null}
                      onMouseLeave={(userData?.roleId == 2 || userData?.roleId == 3
                        || userData?.roleId == 6) && soldStatus === "0"
                        ? this.handleMouseLeave : null}
                    >
                      <Card
                        className="inventrybox inventory-card"
                        style={{
                          cursor: "pointer",
                          //   boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                          "&:hover": {
                            boxShadow:
                              "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                          },
                          // position: row?.is_notsale ? 'relative' : '',                             
                          background: (row.type && (row.type === "misc_watch" || row.type === "misc_product")) ? "antiquewhite" : "white",
                          paddingTop: '20px',
                          paddingBottom: '20px'
                        }}
                      //   onClick={() =>this.handleChangeOfInventoryDetailById(row.inventoryid)}
                      >
                        {/* {row.is_notsale && <div
                              style={{

                                position: 'relative',
                                top: '80px',
                                left: '20px',
                                padding: '4px 45px',
                                transform: 'rotate(45deg)',
                                transformOrigin: 'top right',
                                color: 'white',
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px',
                                textShadow: 'rgba(0, 0, 0, 0.6) 1px 1px 2px',
                                textAlign: 'end',
                                background: 'red',
                              }}>Not for Sale</div>} */}
                        <Grid container spacing={2}>
                          <Grid item xs={1} sm={1} md={1} style={{ padding: "0 15px" }}>
                            {(hoveredCard === row.inventoryid || selectedItems.includes(row.inventoryid)) && (
                              <Checkbox key={row.inventoryid}
                                checked={selectedItems.includes(row.inventoryid)}
                                onChange={() => this.handleCheckboxChange(row.inventoryid)} />
                            )}
                          </Grid>
                          <Grid item xs={row.voidreason ? 10 : 11} sm={row.voidreason ? 10 : 11} md={row.voidreason ? 10 : 11}
                            style={{ padding: "10px 15px" }}>
                            <div
                              onClick={() =>
                                this.handleChangeOfInventoryDetailById(
                                  row.inventoryid, row.invoiceid
                                )
                              }
                              style={{ display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}
                            >
                              <Typography
                                variant="subtitle2"
                                className="textSecondary color-292C2 MuiTypography-textupper"
                                component="p"
                              // style={{ height: '28px', textAlign: "center", display: "block", marginTop: "10px", paddingBottom: "0px" }}
                              >
                                <b>{(row.type === 'misc_watch' || row.type === 'misc_product')
                                  ? (row?.technical_details?.product_title
                                    ? row?.technical_details?.product_title
                                    : (row?.technical_details?.product_name ? row?.technical_details?.product_name : row?.inventory_title))
                                  : (row?.inventory_title)}</b>
                              </Typography>
                              {
                                row.status && row.status === "Void" ? (
                                  <Chip
                                    className="void-status"
                                    size="small"
                                    label={"Void"}
                                    style={{ marginLeft: "10px" }}
                                  />
                                ) : null
                              }
                            </div>
                          </Grid>
                          {row.voidreason ? (
                            <Grid item xs={1} sm={1} md={1} style={{ display: "flex", alignItems: "center", paddingLeft: 0 }} >
                              {
                                row.status && row.status === "Void" ? (
                                  <Tooltip title={`Void Reason: ${row.voidreason}`}>
                                    <InfoOutlinedIcon />
                                  </Tooltip>
                                ) : null
                              }
                            </Grid>
                          ) : (<></>)}
                          <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              paddingTop: "0px"
                            }}
                          >
                            <div
                              className="text-center1"
                              style={{
                                paddingLeft: "30px",
                              }}
                            >
                              {(row.sold_unsold_reason || row.sold_unsold_reason) ? (<Tooltip
                                title={
                                  (row.sold == 1 ?
                                    `${this.state.soldText}${row.sold_unsold_reason}` :
                                    `${this.state.unsoldText}${row.sold_unsold_reason}`
                                  )
                                }
                                placement="right"
                              >

                                <img
                                  src={row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right
                                    || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed
                                    || row?.imagepaths?.wrist_shot || row?.product_imagepaths?.images?.[0] || imageNotAvailable}
                                  alt="rolex"
                                  className="img-responsive1 inventry-img"
                                  onClick={() =>
                                    this.handleChangeOfInventoryDetailById(
                                      row.inventoryid, row.invoiceid
                                    )
                                  }
                                />
                              </Tooltip>) : (<img
                                src={row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right
                                  || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed
                                  || row?.imagepaths?.wrist_shot || row?.product_imagepaths?.images?.[0] || imageNotAvailable}
                                alt="rolex"
                                className="img-responsive1 inventry-img"
                                onClick={() =>
                                  this.handleChangeOfInventoryDetailById(
                                    row.inventoryid, row.invoiceid
                                  )
                                }
                              />)}

                              {this.state.setting && this.state.setting?.manufacturer_price && (
                                <div style={{ marginTop: 10 }}>
                                  {row?.manufactureprice && row?.manufactureprice != "0.00" ?
                                    <>
                                      <Typography >Manufacturer</Typography>
                                      <Typography>List Price:</Typography>
                                      <Typography>$ {row?.manufactureprice}</Typography>
                                    </>
                                    : <>
                                      <Typography >Manufacturer</Typography>
                                      <Typography>List Price:</Typography>
                                      <Typography><i>Not Available</i></Typography>
                                    </>}
                                </div>
                              )}

                            </div>
                          </Grid>
                          <Grid item xs={12} sm={8} md={8} style={{ paddingTop: "0px" }}>
                            <div className="space-1 text-center1">
                              <Grid container spacing={2}>
                                {/* <Grid item xs={12} sm={12} md={12}>
                                      <div
                                        onClick={() =>
                                          this.handleChangeOfInventoryDetailById(
                                            row.inventoryid
                                          )
                                        }
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          className="textSecondary color-292C2 MuiTypography-textupper"
                                          component="p"
                                        style={{height:'50px'}}
                                        >
                                          <b>{row?.inventory_title}</b>
                                        </Typography>
                                      </div>
                                    </Grid> */}

                                <Grid item xs={12} sm={12} md={12}>
                                  <div
                                    onClick={() =>
                                      this.handleChangeOfInventoryDetailById(
                                        row.inventoryid, row.invoiceid
                                      )
                                    }
                                  >
                                    {row.type !== 'misc_product' && (
                                      <>
                                        {this.state.setting && this.state.setting.brandname && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Brand :{" "}
                                            {(row.type === 'misc_watch' || row.type === 'misc_product') ? (row?.technical_details?.brand_name ? row?.technical_details?.brand_name : '-') : ((row?.brandname) ? row?.brandname : '-')
                                            }
                                          </Typography>
                                          <br />
                                        </>)}

                                        {this.state.setting && this.state.setting.reference && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Ref # :{" "}
                                              {(row?.prodattr?.reference) ? row?.prodattr?.reference : '-'
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}

                                        {this.state.setting && this.state.setting.dialcolor && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Dial Color :{" "}
                                              {(row?.technical_details?.dial_color) ? row?.technical_details?.dial_color : row?.prodattr?.dialcolor ? row?.prodattr?.dialcolor : '--'
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}

                                        {this.state.setting && this.state.setting.box && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Box :{" "}
                                              {(row?.warrantybox && row.warrantybox === '1'
                                                ? "Yes"
                                                : "No")
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}

                                        {this.state.setting && this.state.setting.warranty && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Warranty  :{" "}
                                              {row.warrantypaper ? "Yes"
                                                : "No"
                                              }
                                            </Typography>
                                            <br />
                                            <Typography variant="p" className="inventory-tile">
                                              Warranty Year :{" "}
                                              {/* {row?.warrantydate ? moment(row?.warrantydate).format("YYYY") : "-"} */}
                                              {row?.warrantydate ? formatDate(row?.warrantydate, "YYYY") : "-"}
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.condition && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Condition  :{" "}
                                            {row.condition && row.condition === 4 ? 'New' :
                                              row.condition && row.condition === 3 ? 'Retail Ready' :
                                                row.condition && row.condition === 2 ? 'Mint' :
                                                  'Pre-owned'
                                            }
                                          </Typography>
                                          <br />
                                        </>)}

                                        {/* {row.targetwholesaleprice && (
                                              <>
                                                <Typography variant="p" className="inventory-tile">
                                                  Target Wholesale Price:{" "}
                                                  $ {row.targetwholesaleprice}
                                                </Typography>
                                                <br />
                                              </>
                                            )} */}

                                        {this.state.setting && this.state.setting.inventorystatus && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Inventory Status :{" "}
                                            {
                                              (row?.inventory_status
                                                ? InventoryStatusLookup[row?.inventory_status]
                                                : "---")
                                            }
                                          </Typography>
                                          <br />
                                        </>)}
                                        {this.state.setting && this.state.setting.locations && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Inventory Location :{" "}
                                            {
                                              (row?.locations
                                                ? row?.locations
                                                : "---")
                                            }
                                          </Typography>
                                          <br />
                                        </>)}


                                        <>
                                          <Typography variant="p" className="inventory-tile">
                                            Inventory ID :{" "}
                                            {
                                              row?.inventoryid ? row?.inventoryid : '-'

                                            }
                                          </Typography>
                                          <br />
                                        </>
                                        {this.state.setting && this.state.setting.wholesaleprice && (<>
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              WholeSale Price :{" "}
                                              {
                                                row?.targetwholesaleprice ? row?.targetwholesaleprice : '--'

                                              }
                                            </Typography>
                                            <br /></>

                                        </>)}
                                        {this.state.setting && this.state.setting.enccustomerprice && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              End Customer Price :{" "}
                                              {
                                                row?.targetendcustomerprice ? row?.targetendcustomerprice : '--'
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.tagprice && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Tag Price :{" "}
                                              {
                                                row?.tag_price ? '$ ' + row?.tag_price : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>)}
                                        {this.state.setting && this.state.setting.purchase_payment_status && (
                                          <>
                                            <Typography variant="p" className="inventory-tile" style={{ display: 'flex' }}>
                                              Purchase Payment Status :{" "}
                                              <div style={{ marginLeft: 2 }}>
                                                {
                                                  row?.purchase_invoice_paid ? 'Paid' : 'Not Paid'
                                                  //  <CancelSharpIcon sx={{color:'red'}}/>

                                                }
                                              </div>

                                            </Typography>
                                            <br />
                                          </>)}
                                        {row?.purchase_invoice_paid ?
                                          (this.state.setting.purchase_payment_date ?
                                            <>
                                              <Typography variant="p" className="inventory-tile">
                                                Purchase Payment Date :{" "}
                                                {/* {row?.purchase_payment_date ? moment(row?.purchase_payment_date).format('MM/DD/YYYY') : '--'} */}
                                                {row?.purchase_payment_date ? formatDate(row?.purchase_payment_date, 'MM/DD/YYYY') : '--'}
                                              </Typography>
                                              <br />
                                            </> : null) :

                                          (this.state.setting.purchase_payment_date ?

                                            <>
                                              <Typography variant="p" className="inventory-tile">
                                                Purchase Payment Due Date :{" "}
                                                {/* {row?.purchase_payment_duedate ? moment(row?.purchase_payment_duedate).format('MM/DD/YYYY') : '--'} */}
                                                {row?.purchase_payment_duedate ? formatDate(row?.purchase_payment_duedate, 'MM/DD/YYYY') : '--'}
                                              </Typography>
                                              <br />
                                            </> : null)}
                                        {this.state.setting && this.state.setting.purchase_payment_details && (

                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Purchase Payment Details :{" "}
                                              {
                                                row?.purchase_payment_details ? row?.purchase_payment_details : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>)}
                                      </>
                                    )}
                                    {row.type === 'misc_product' && (
                                      <>

                                        {this.state.setting && this.state.setting.productname && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Product Name:{" "}
                                            {(row &&
                                              row
                                                ?.technical_details
                                                ?.product_title ?
                                              row
                                                ?.technical_details
                                                ?.product_title
                                              : "---")
                                            }
                                          </Typography>
                                          <br />
                                        </>)}
                                        {this.state.setting && this.state.setting.brandname && (<>
                                          <Typography variant="p" className="inventory-tile">
                                            Brand:{" "}
                                            {row?.technical_details?.brand_name ? row?.technical_details?.brand_name : '-'}
                                          </Typography>
                                          <br />
                                        </>)}
                                        {this.state.setting && this.state.setting.reference && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Ref # :{" "}
                                              {(row?.prodattr?.reference) ? row?.prodattr?.reference : '-'
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.category && (
                                          <>
                                            <Typography variant="p" className="text-capitalize inventory-tile">
                                              Category:{" "}
                                              {(row &&
                                                row.category ?
                                                row.category
                                                : "---")
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.condition && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Condition  :{" "}
                                              {row.condition && row.condition === 1 ? 'New' :
                                                'Used'
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {/* {row.targetwholesaleprice && (
                                              <>
                                                <Typography variant="p" className="inventory-tile">
                                                  Target Wholesale Price:{" "}
                                                  $ {row.targetwholesaleprice}
                                                </Typography>
                                                <br />
                                              </>
                                            )} */}
                                        {this.state.setting && this.state.setting.inventorystatus && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Inventory Status :{" "}
                                              {
                                                (row?.inventory_status
                                                  ? InventoryStatusLookup[row?.inventory_status]
                                                  : "---")
                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        <>
                                          <Typography variant="p" className="inventory-tile">
                                            Inventory ID :{" "}
                                            {
                                              row?.inventoryid ? row?.inventoryid : '-'

                                            }
                                          </Typography>
                                          <br />
                                        </>

                                        {this.state.setting && this.state.setting.wholesaleprice && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              WholeSalePrice :{" "}
                                              {
                                                row?.targetwholesaleprice ? row?.targetwholesaleprice : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.enccustomerprice && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              End Customer Price :{" "}
                                              {
                                                row?.targetendcustomerprice ? row?.targetendcustomerprice : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>
                                        )}
                                        {this.state.setting && this.state.setting.tagprice && (
                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Tag Price :{" "}
                                              {
                                                row?.tag_price ? '$ ' + row?.tag_price : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>)}
                                        {this.state.setting && this.state.setting.purchase_payment_status && (

                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Purchase Payment Status :{" "}
                                              {
                                                row?.purchase_invoice_paid ? 'Paid' : 'Not Paid'

                                              }
                                            </Typography>
                                            <br />
                                          </>)}
                                        {row?.purchase_invoice_paid ?
                                          (this.state.setting.purchase_payment_date ?

                                            <>
                                              <Typography variant="p" className="inventory-tile">
                                                Purchase Payment Date :{" "}
                                                {/* {row?.purchase_payment_date ? moment(row?.purchase_payment_date).format('MM/DD/YYYY') : '--'} */}
                                                {row?.purchase_payment_date ? formatDate(row?.purchase_payment_date, 'MM/DD/YYYY') : '--'}
                                              </Typography>
                                              <br />
                                            </> : null) :
                                          (this.state.setting.purchase_payment_duedate ?

                                            <>

                                              <Typography variant="p" className="inventory-tile">
                                                Purchase Payment Due Date :{" "}
                                                {/* {row?.purchase_payment_duedate ? moment(row?.purchase_payment_duedate).format('MM/DD/YYYY') : '--'} */}
                                                {row?.purchase_payment_duedate ? formatDate(row?.purchase_payment_duedate, 'MM/DD/YYYY') : '--'}
                                              </Typography>
                                              <br />
                                            </> : null)}
                                        {this.state.setting && this.state.setting.purchase_payment_details && (

                                          <>
                                            <Typography variant="p" className="inventory-tile">
                                              Purchase Payment Details :{" "}
                                              {
                                                row?.purchase_payment_details ? row?.purchase_payment_details : '--'

                                              }
                                            </Typography>
                                            <br />
                                          </>)}
                                      </>

                                    )}
                                  </div>
                                </Grid>
                                {row.sold == 1 ? (
                                  <div
                                    style={{
                                      opacity: "0.4",
                                    }}
                                  >
                                    <Grid container spacing={1}>
                                      <Grid item xs={6} sm={6} md={5}>
                                        <div
                                          style={{
                                            marginTop: "7px",
                                            marginLeft: "10px",
                                            width: "77px",
                                          }}
                                        >
                                          {/* <img src={ebay} alt="ebay" className="img-responsive1" /> */}
                                        </div>
                                      </Grid>
                                      <Grid item xs={6} sm={6} md={5}>
                                        {/* <div className="width-100 space-top-10">
                                                                        <img src={Chrono} alt="Chrono" className="img-responsive1" />
                                                                    </div> */}
                                      </Grid>
                                    </Grid>
                                  </div>
                                ) : null}
                                {/*    <div > //  <Grid container spacing={1}>
                                                                //    <Grid item xs={6} sm={6} md={5}>
                                                                //     <div style={{marginTop: '7px',marginLeft:'10px',
                                                                //      width: '77px'}}>
                                                                        {/* <img src={ebay} alt="ebay" className="img-responsive1" /> */}
                                {/* </div>
                                                                    </Grid>
                                                                   <Grid item xs={6} sm={6} md={5}> */}
                                {/* <div className="width-100 space-top-10">
                                                                        <img src={Chrono} alt="Chrono" className="img-responsive1" />
                                                                    </div> */}
                                {/*</Grid> 
                                                                    </Grid> 
                                                                    </div>*/}

                                <Grid item xs={12} sm={12} md={1}></Grid>
                                <div
                                  style={{
                                    position: "fixed",
                                    right: "20px",
                                    bottom: "70px",
                                  }}
                                >
                                  <Link href="../create-inventory">
                                    <img
                                      src={plusSign}
                                      alt="plusSign"
                                      width="60px"
                                    />
                                  </Link>
                                </div>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                  }}
                                >

                                  {isActionAllowed(this.state.accessData, 'void_inventory') && row.status &&
                                    row.status === "Active" && (
                                      <BasicMenu
                                        row={row}
                                        searchWatch={
                                          this.SearchInventoryWatchesdetails
                                        }
                                      />
                                    )}
                                  {isActionAllowed(this.state.accessData, 'update_sales_status') && (
                                    <>
                                      <FormGroup>
                                        {(row.sold_unsold_reason || row.sold_unsold_reason) ? (
                                          <Tooltip
                                            title={
                                              (row.sold == 1 ?
                                                `${this.state.soldText}${row.sold_unsold_reason}` :
                                                `${this.state.unsoldText}${row.sold_unsold_reason}`
                                              )
                                            }
                                            placement="top"
                                          >
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={row.sold === 1}
                                                  name="hdsdsdbjb"
                                                  disabled={row.status === "Void"}
                                                />
                                              }
                                              onChange={(e) =>
                                                this.handleSwitch(row, e)
                                              }
                                              // label={
                                              //   row.sold === 1 ? "sold" : "unsold"
                                              // }
                                              style={{ margin: "0px" }}
                                            />
                                          </Tooltip>
                                        ) : (<FormControlLabel
                                          control={
                                            <Switch
                                              checked={row.sold === 1}
                                              name="hdsdsdbjb"
                                              disabled={row.status === "Void"}
                                            />
                                          }
                                          onChange={(e) =>
                                            this.handleSwitch(row, e)
                                          }
                                          // label={
                                          //   row.sold === 1 ? "sold" : "unsold"
                                          // }
                                          style={{ margin: "0px" }}
                                        />)}

                                      </FormGroup>
                                    </>
                                  )}
                                  {isActionAllowed(this.state.accessData, 'create_invoice') && (
                                    <>
                                      {row.sold !== 1 &&
                                        row.status !== "Void" && (
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            className="text-capitalize primary-bg color-white"
                                            style={{
                                              marginTop: "4px",
                                              padding: "4px 6px",
                                              fontSize: "12px",
                                            }}
                                            onClick={() => {
                                              this.props.history.push({
                                                pathname: "/create/invoice",
                                                // pathname: (row.type === "misc_product"  || row.type === "misc_watch") ? "/create/invoice/misc" : "/create/invoice",
                                                state: {
                                                  inventoryDetails: {
                                                    model: (row.type === "misc_product" || row.type === "misc_watch") ? (row
                                                      ?.technical_details
                                                      ?.product_title ? row
                                                        ?.technical_details
                                                      ?.product_title : (row
                                                        ?.technical_details
                                                        ?.product_name ? row
                                                          ?.technical_details
                                                        ?.product_name : "")) :
                                                      row?.prodattr
                                                        ?.reference,
                                                    serialno: row?.serialno,
                                                    productid: row?.productid,
                                                    desc: (row.type === "misc_product" || row.type === "misc_watch") ?
                                                      (row
                                                        ?.technical_details
                                                        ?.product_title ? row
                                                          ?.technical_details
                                                        ?.product_title : (row
                                                          ?.technical_details
                                                          ?.product_name ? row
                                                            ?.technical_details
                                                          ?.product_name : "")) : (row?.inventory_title ? row.inventory_title : row?.prodattr?.model ? row.prodattr.model : ""),
                                                    image: row?.imagepaths
                                                      ? (row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed || row?.imagepaths?.wrist_shot)
                                                      : (row?.product_imagepaths?.images
                                                        ? row?.product_imagepaths?.images
                                                        : imageNotAvailable),
                                                    inventoryid:
                                                      row?.inventoryid,
                                                    purchaseprice:
                                                      row?.purchaseprice,
                                                    quantity: row
                                                      ?.quantity,
                                                    productName: (row.type === "misc_product" || row.type === "misc_watch") ?
                                                      row
                                                        ?.technical_details
                                                        ?.product_title ? row
                                                          ?.technical_details
                                                        ?.product_title : (row
                                                          ?.technical_details
                                                          ?.product_name ? row
                                                            ?.technical_details
                                                          ?.product_name : "") : row?.description,
                                                    type: row.type
                                                  },
                                                },
                                              });
                                            }}
                                          >
                                            Create Invoice
                                          </Button>
                                        )}
                                    </>)}
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  );
                })
                }
              </Grid>
              {/* )} */}
            </InfiniteScroll>}
          {inventoryList?.length === 0 && this.state.loaded && this.state.noItemFound &&
            <Box
              component="span"
              sx={{
                display: "block",
                border: "2px dashed grey",
                width: "100%",
                padding: "50px 72px",
                // marginTop: "20px",
              }}
            >
              {" "}
              <Container>
                <Typography
                  variant="h6"
                  className="color-BCBCBC"
                  component="p"
                  align="center"
                >
                  <b>No Records Found</b>
                </Typography>
              </Container>
            </Box>
          }
          {this.state.open && (
            <InvoiceModal
              open={this.state.open}
              handleClose={this.handleClose}
            />
          )}
          {this.state.openReasonChange && (
            <ReasonChange
              open={this.state.openReasonChange}
              handleClose={this.handleCloseOfReasonChange}
              changeRangeName={this.changeRangeName}
            />
          )
          }
          {this.state.openDeleteModal && (
            <Dialog
              open={this.state.openDeleteModal}
              // onClose={() => this.setState({ openDeleteModal: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle>
                Delete
                <IconButton style={{ float: 'right' }}
                  onClick={() => this.setState({ openDeleteModal: false, isDeleteAll: false })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div> Are you sure you want to <b>Delete</b>? </div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus onClick={() => this.setState({ openDeleteModal: false, isDeleteAll: false })}>
                  Cancel
                </Button>
                <Button color="primary" onClick={(e) => this.handleOTP(e)} disabled={this.state.disabled}>Ok</Button>
              </DialogActions>
            </Dialog>
          )}
          {this.state.openOtpModel && (
            <Dialog
              open={this.state.openOtpModel}
              // onClose={() => this.setState({ openOtpModel: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            // fullWidth={true}
            // maxWidth={'sm'}
            >
              <DialogTitle style={{ borderBottom: '1px solid #ccc' }}>
                OTP Verification Code
                <IconButton style={{ float: 'right' }}
                  onClick={() => this.setState({ openOtpModel: false, isDeleteAll: false })}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Container className="space-1">
                  <Paper className="border-radius" style={{ boxShadow: 'none' }}>
                    {/* <div className="text-center">
                      <Typography variant="h5" gutterBottom align="center" className="text-white">
                        OTP Verification Code
                      </Typography>
                    </div> */}
                    <div>
                      <Typography variant="small">
                        Please Check your Email for a message with your code. Your Code is 6 digits long.
                      </Typography>
                    </div>
                    <form className="padding"
                      onSubmit={(e) => { e.preventDefault(); this.handleVerifyOTP(inventoryList) }}
                      id='otp_form' >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12}>
                          <FormControl className="form-width-100 ">
                            <InputLabel htmlFor="component-simple" className="color-BCBCBC">OTP</InputLabel>
                            <Input id="component-simple"
                              onChange={this.onOtpChange}
                            />
                            <FormHelperText className="error-o">{this.state.otpErrorText}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} disabled={this.state.disabled} >Verify</Button>
                      </Grid>
                    </form>

                    {this.state.showing
                      ?
                      <div className="text-center1">
                        <a href="" onClick={this.onResendOtp} disableElevation style={{ color: '#CEA05F' }}><b>Resend OTP </b></a>
                      </div>
                      : <div className="text-center1">Didn't receive the code? Request New Code in {this.state.twoMinutes}
                      </div>
                    }
                    <div className="space-1"></div>
                  </Paper>
                </Container>
              </DialogContent>
              <DialogActions>
              </DialogActions>
            </Dialog>
          )}
          {this.state.openShareModal && (
            <Dialog
              open={this.state.openShareModal}
              // onClose={() => this.setState({ openDeleteModal: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth={'sm'}
            >
              <DialogTitle>
                Share Inventory
                <IconButton style={{ float: 'right' }}
                  onClick={() => this.setState({ openShareModal: false })}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <div> Are you sure you want to <b>Share</b> this items? </div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" autoFocus onClick={() => this.setState({ openShareModal: false })}>
                  Cancel
                </Button>
                <Button color="primary" onClick={(e) => this.handleShareInventory(e)} disabled={this.state.disabled}>Ok</Button>
              </DialogActions>
            </Dialog>
          )}
          {/* <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={inventoryList.length}
            rowsPerPage={this.state.rowsPerPage}
            SelectProps={{
              native: true
            }}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          /> */}
        </Container>

        {/* </Loader> */}
      </div >
    );
  }

  render() {
    return (
      <ContentWrapper contentView={this.state.userData && (!this.state.userData?.planDetails || this.state.userData?.planDetails === "Noplan")
        && (!this.state.userData?.dealerInventorySubscription || this.state.userData?.dealerInventorySubscription === 'access_only' || this.state.userData?.dealerInventorySubscription === 'no_subscription' || this.state.userData?.isDealerInventoryExpired === true) ?
        <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : this.inventoryrender()} >
      </ContentWrapper>
    )
  }
}

function mapStateToProps(state) {
  const { InventoryList, auth, browseWatchesInfo, inventoryDetailByIdInfo } =
    state;
  return {
    InventoryList: InventoryList.InventoryList,
    currentUser: auth.currentUser,
    // dialColorInfo: browseWatchesInfo.dialColorInfo,
    brandInfo: browseWatchesInfo.brandInfo,
    inventoryXlIdInfo: InventoryList.inventoryXlIdInfo,
    // inventoryDetailByIdInfo: inventoryDetailByIdInfo.inventoryDetailByIdInfo
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInventoryWatches,
      filterBySearch,
      brandList,
      inventoryDetailById,
      fetchInventoryExcelList,
      SearchInventoryWatches,
      recoverPassword,
      verifyOtp,
      // dialColor,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryComponent);
