import { React, useState } from 'react';
import ContentWrapper from '../../../../common/components/contentWrapper';
import {
    Grid,
    Typography,
    FormControl,
    InputLabel,
    Paper,
    Container,
    FormHelperText,
    Button,
    MenuItem,
    Select
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { getComponentActionList, isActionAllowed } from '../../../../common/components/Helper/Utils';
import AccessRestricted from '../../common/components/accessRestricted'
import UpgradePlan from '../../common/components/upgradePlan';
import { useEffect } from 'react';

function CreateInventoryPage() {
    const history = useHistory();
    const [selectedValue, setSelectedValue] = useState("");
    const [accessData, setAccessData] = useState([]);
    const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;

    useEffect(() => {
        const accessData1 = getComponentActionList('add_inventory')
        setAccessData(accessData1)
    }, [])
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const handleNavigate = () => {
        if (selectedValue === "1") {

            history.push("/dealer");
        }
        else if (selectedValue === "2") {
            history.push("/misc-watch");
        }
        else if (selectedValue === "3") {
            localStorage.setItem("pageTilte", "WATCH RELATED ACCESSORIES")
            history.push("/misc-product");
        }
        else if (selectedValue === "4") {
            localStorage.setItem("pageTilte", "APPAREL")
            history.push("/misc-product");
        }
        else if (selectedValue === "5") {
            localStorage.setItem("pageTilte", "WATCH PARTS")
            history.push("/misc-product");
        }
        else if (selectedValue === "6") {
            localStorage.setItem("pageTilte", "JEWELRY")
            history.push("/misc-product");
        }
        else if (selectedValue === "7") {
            localStorage.setItem("pageTilte", "STONES")
            history.push("/misc-product");
        }
        else if (selectedValue === "8") {
            localStorage.setItem("pageTilte", "OTHERS")
            history.push("/misc-product");
        }
    };

    const getContentView = () => {
        return (
            <>
                {isActionAllowed(accessData, "add_inventory") ?
                    (
                        <div style={{ display: 'flex', justifyContent: 'center' }} >
                            <Container maxWidth="sm" className="space-3" >
                                <Paper className="border-radius" >
                                    <div className="text-center">
                                        <Typography variant="h6" component="h6" gutterBottom align="center" className="text-white">
                                            ADD INVENTORY
                                        </Typography>
                                    </div>
                                    <div className="text-center1" style={{ marginBottom: '20px' }}>
                                        <Typography variant="h6" className="text-center1">
                                            Please select the type to create the inventory
                                        </Typography>
                                    </div>

                                    <Grid container spacing={3} style={{ padding: '10px 30px 20px 30px' }}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <FormControl variant="standard" className='formControl'>
                                                <InputLabel id="demo-simple-select-standard-label">Select the inventory type</InputLabel>
                                                <Select
                                                    className='select-input'
                                                    style={{ color: "black", marginBottom: '10px' }}
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedValue}
                                                    onChange={handleSelectChange}
                                                >
                                                    <MenuItem value="1" >Standard Watch</MenuItem>
                                                    <MenuItem value="2">Misc Watch</MenuItem>
                                                    <MenuItem value="3">Watch Related Accessories</MenuItem>
                                                    <MenuItem value="4">Apparel</MenuItem>
                                                    <MenuItem value="5">Watch Parts</MenuItem>
                                                    <MenuItem value="6">Jewelry</MenuItem>
                                                    <MenuItem value="7">Stones</MenuItem>
                                                    <MenuItem value="8">Others</MenuItem>
                                                </Select>
                                                <FormHelperText className="error-o"></FormHelperText>
                                            </FormControl>
                                        </Grid>
                                        <Button type="submit" className="SubmitColor space-m-1" fullWidth={true} onClick={handleNavigate} >Add Inventory</Button>
                                    </Grid>
                                </Paper>
                            </Container>
                        </div>
                    ) : <AccessRestricted />
                }
            </>


        );
    };

    return (
        <ContentWrapper contentView={userDetails && (!userDetails?.planDetails || userDetails?.planDetails === "Noplan") ?
            <div style={{ marginTop: "25px" }}> <UpgradePlan /> </div> : getContentView()} >
        </ContentWrapper>
    );
}

export default CreateInventoryPage;
