const constants = {
    BASE_URL: 'https://api.watchops.com/',
    ENDPOINT: 'https://api.watchops.com/',
    SHARE_INVENTORY: 'https://app.watchops.com/',
    PAGINATION_LIMIT: 12,
    PAGINATION_START: 1,
    MODEL_LIMIT: 100,
    DEALER_LIMIT: 50,
    DASHBOARD_DEALER_LIMIT: 1000,
    USER_INACTIVE_TIME: 43200000, // 12hours 43200000
    PUBNUB_PUBLISHKEY: 'pub-c-41484343-dcba-4941-9083-fd187128b9a9',
    PUBNUB_SUBSCRIBEKEY: 'sub-c-2c966a21-0d78-4a2b-8e48-485fa2ba8583',
    PUBNUB_UUID: 'sec-c-MGNkODAzYWUtZDE3YS00M2UyLWEzMjQtMGE3MWU0NDI3MTc4'
}
export default constants;