import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Container,
  Grid,
  Typography,
  FormGroup,
  Button,
  Link,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  Paper,
  MenuItem,
  Checkbox,
  Box,
  FormHelperText,
  Slider,
  Chip,
  Tooltip,
} from "@material-ui/core";
import { alpha } from "@mui/material/styles";
import { InputBase } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@material-ui/icons/Clear";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MaterialTable from "material-table";
import Icon from "@material-ui/core/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import imageNotAvailable from '../../../../assests/images/img-soon.png';
import { getComponentActionList, isActionAllowed } from "../../../../common/components/Helper/Utils";
import {
  fetchInventoryWatches,
  brandList,
  filterBySearch,
  inventoryDetailById,
  SearchInventoryWatches,
  fetchInventoryExcelList,
} from "../actions/dashboardAction";
import _ from "lodash";
import searchinventry from "../actions/dashboardaapi";
import plusSign from "../../../../assests/images/Group 1771.png";
import Card from "@material-ui/core/Card";
import InvoiceModal from "./invoiceModal";
import TablePagination from "@mui/material/TablePagination";
import { styled } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { toast } from "react-toastify";
import { SpinnerRoundFilled } from "spinners-react";
import ViewInvoicemodel from "./ViewInvoicemodel";
import VoidInventory from "./voidInventory";
import { voidInventoryUpdate } from "../actions/dashboardAction";
import { trim } from "lodash";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import { th } from "date-fns/locale";
import ContentWrapper from "../../../../common/components/contentWrapper";
import ReasonChange from './SoldAndUnsoldModel';
import BulkUpload from '@mui/icons-material/UploadFileOutlined';

// import Slider from 'react-slick';
const InventoryStatusLookup = {
  "1": "Inhand",
  "2": "Incoming",
  "3": "Out on Memo",
  "4": "On Hold / Reserved",
  "5": "Available to Order",
  "6": "Needs Service / Polish",
  "7": "At Service",
  "8": "Need to Ship",
  "9": "Shipped waiting on payment",
  "10": "Sold & Cashed Out",
  "11": "Intake Complete",
};
const StyledMenu = styled((props) => (
  <Menu
    // elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const BasicMenu = ({ row, searchWatch, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [viewInvoiceModal, setviewInvoiceModal] = React.useState(false);
  const [voidInventoryModal, setvoidInventoryModal] = React.useState(false);
  const [selectedInventory, setSelectedInventory] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setviewInvoiceModal(false);
    setvoidInventoryModal(false);
    setvoidInventoryModal(false);
    setSelectedInventory(null);
  };
  const viewInvoice = (row) => {
    setAnchorEl(null);
    setviewInvoiceModal(!viewInvoiceModal);
    setSelectedInventory(row);
  };
  const voidInvoice = (row) => {
    setAnchorEl(null);
    setvoidInventoryModal(!voidInventoryModal);
    setSelectedInventory(row);
  };
  const updateInventoryDetails = (reason) => {

    if (trim(reason) !== "") {
      const data = {
        inventoryid: selectedInventory.inventoryid,
        reason: trim(reason),
      };
      voidInventoryUpdate(data, (success, error) => {
        if (success) {
          handleClose(true);
          searchWatch();
        }
        if (error) {
          handleClose(true);
          toast("Something went wrong", {
            autoClose: 5000,
            type: toast.TYPE.ERROR,
            position: toast.POSITION.BOTTOM_CENTER,
          });
        }
      });
    }
  };
  return (
    <div>
      <IconButton
        // className="primary-bg color-white"
        type="button"
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{ marginLeft: "5px", padding: "5px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {row.status === "Active" && row.sold !== 1 && (
          <MenuItem
            onClick={() => {
              voidInvoice(row);
            }}
          >
            Void Inventory
          </MenuItem>
        )}
        {row.sold === 1 &&
          (row.invoiceid ? (
            <MenuItem
              onClick={() => {
                viewInvoice(row);
              }}
            >
              View Invoice
            </MenuItem>
          ) : (


            <MenuItem disabled>Invoice Not Associated</MenuItem>


          ))}

      </StyledMenu>
      {viewInvoiceModal && (
        <ViewInvoicemodel
          open={viewInvoiceModal}
          handleClose={handleClose}
          invoiceid={{
            id: selectedInventory.invoiceid,
          }}
        />
      )}
      {voidInventoryModal && (
        <VoidInventory
          open={voidInventoryModal}
          handleClose={handleClose}
          inventory={selectedInventory}
          updateInventoryDetails={updateInventoryDetails}
        />
      )}
    </div>
  );
};

const CustomizedUploadMenus = ({ downloadInventory, history }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    setAnchorEl(null);
    if (data) {
      downloadInventory(data)
    }
  };
  const bulUploadStandaWatch = () => {
    history.push({
      pathname: "/bulk-upload",
    });
  }
  const bulUploadMiscBucket = () => {
    history.push({
      pathname: "/misc-bucket-bulk-upload",
    });
  }

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        className={
          "text-capitalize primary-bg color-white"
        }
        style={{ float: "right" }}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        <BulkUpload />&nbsp;BulK Upload
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={
          bulUploadStandaWatch
        } disableRipple>
          BulK Upload For Standard Watches
        </MenuItem>
        {/* <MenuItem onClick={bulUploadMiscBucket} disableRipple>
           BulK Upload For Misc Products
        </MenuItem> */}
      </StyledMenu>
    </div>
  );
}
const CustomizedMenus = ({ downloadInventory }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    setAnchorEl(null);
    if (data) {
      downloadInventory(data)
    }
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        className={
          "text-capitalize primary-bg color-white"
        }
        style={{ float: "right" }}
        endIcon={<KeyboardArrowDownOutlined />}
      >
        Download Inventory
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("PDF")} disableRipple>
          Download Pdf
        </MenuItem>
        <MenuItem onClick={() => handleClose("EX")} disableRipple>
          Download Excel
        </MenuItem>
      </StyledMenu>
    </div>
  );
};
const Loader = require("react-loader");
const followersMarks1 = [
  {
    value: "0",
    scaledValue: 0,
    label: "ALL",
  },
  {
    value: "Unknown",
    scaledValue: 1,
    label: "UNKNOWN",
  },
  {
    value: 30,
    scaledValue: 2,
    label: "30 d",
  },
  {
    value: 60,
    scaledValue: 3,
    label: "60 d",
  },
  {
    value: 90,
    scaledValue: 4,
    label: "90 d",
  },
  {
    value: 120,
    scaledValue: 6,
    label: "120 d",
  },
  {
    value: 150,
    scaledValue: 8,
    label: "150 d",
  },
  {
    value: 180,
    scaledValue: 9,
    label: "180 d",
  },
];
class DealerInventoryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      searchEnabled: false,
      inventoryid: "",
      productid: "",
      purchaseprice: "",
      // purchasedate: "",
      modledata: "",
      purchasefrom: "",
      CaseSize: "",
      CaseMaterial: "",
      BandMaterial: "",
      disable: "",
      selectedProduct: { productId: "" },
      sold: true,
      avail: false,
      soldbtn: false,
      BrandName: "",
      brand_id: "",
      brand_description: "",
      InventoryType: "",
      InventoryStatus: '',
      key: "",
      data: [],
      searchString: "",
      nonsearchstring: true,
      updatedInvenentryList: [],
      errorMessage: "",
      inventory_id: "",
      listed_on: "all",
      sold_status: "",
      loaded: false,
      UserId: "",
      purchasedate: 0,
      autocomplete: {
        currentInput: "",
        loading: false,
        data: [],
      },
      cursor: 0,
      inventoryData: [],

      selected: { product: null },
      copied: false,
      setCopied: false,
      soldStatus: "0",
      dataSoldUnsold: false,
      removedSoldUnsold: "",
      open: false,
      rowsPerPage: 10,
      page: 0,
      downloadloading: false,
      inventoryxlid: this.props?.match?.params?.bulkUploadId
        ? this.props?.match?.params?.bulkUploadId
        : "",
      anchorE2: null,
      openDropDown: false,
      inventoryStatus: "ALL",
      openE2: false,
      InventoryCategory: null,
      type: null,
      accessData: null,
      userData: sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null,
      reasonChange: '',
      openReasonChange: false,
      rowDetails: '',
      soldText: 'Sold Reason:',
      unsoldText: 'Unsold Reason:'
    };

    this.changeRangeName = this.changeRangeName.bind(this);
    this.SearchInventoryWatchesdetails =
      this.SearchInventoryWatchesdetails.bind(this);
    this.handleChangeOfBrandByBrandName =
      this.handleChangeOfBrandByBrandName.bind(this);
    this.handleChangeOfInventoryDetailById =
      this.handleChangeOfInventoryDetailById.bind(this);
    this.autocomplete = this.autocomplete.bind(this);
    this.checkboxval = this.checkboxval.bind(this);
    this.removebrandname = this.removebrandname.bind(this);
    this.handleChangeOfSoldUnSold = this.handleChangeOfSoldUnSold.bind(this);
    this.removeSoldUnsold = this.removeSoldUnsold.bind(this);
    this.removeBulkUploadId = this.removeBulkUploadId.bind(this);
    this.removeInventoryType = this.removeInventoryType.bind(this);
    this.removeInventoryStatus = this.removeInventoryStatus.bind(this);
    this.handleChangeOfBulkUploadId =
      this.handleChangeOfBulkUploadId.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.downloadInventory = this.downloadInventory.bind(this);
    // this.createInvoice = this.createInvoice(this);
    this.inventoryPurchaseDateInterval =
      this.inventoryPurchaseDateInterval.bind(this);
    this.valueLabelFormat = this.valueLabelFormat.bind(this);
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handleChangeOfInventoryStatus =
      this.handleChangeOfInventoryStatus.bind(this);
    this.removetype = this.removetype.bind(this);
    this.removeInventoryCategory = this.removeInventoryCategory.bind(this);
    this.handleCloseOfReasonChange = this.handleCloseOfReasonChange.bind(this);
    this.handleSwitch = this.handleSwitch.bind(this);
  }
  componentWillMount() {
    const accessData = getComponentActionList('my_inventory')
    this.setState({ accessData: accessData })
  }

  componentDidMount() {
    // const userDetails = sessionStorage.getItem("userDetails") ? JSON.parse(sessionStorage.getItem("userDetails")) : null;
    // this.setState({userData: userDetails});

    if (this.props.history && this.props.history.location && this.props.history.location.state && this.props.location.state.inventoryCacheState) {
      this.setState({ ...this.props.location.state.inventoryCacheState });
    } else {

      this.setState({
        UserId:
          this.props && this.props.currentUser && this.props.currentUser.userId,
      });
      setTimeout(() => {
        this.setState({ loaded: true });
      }, 2000);

      const token = localStorage.getItem("token");

      if (token) {
        if (this.props.currentUser) {
          // this.props.fetchInventoryWatches(this.state.UserId);
        }
        let PId = null;
        try {
          PId = this.props.match.params.productId;
          if (PId) {
            this.state.selectedProduct.productId = PId;
            this.setState({
              ...this.state,
            });
            this.props.fetchSearchBrowseWatches(PId);
          } else {
            this.props.fetchBrowseWatches();
          }
        } catch (error) {
          console.log(error);
        }
        this.props.brandList();
        this.checkboxval();
      } else {
        window.location.href = "/login";
      }
    }
    //this.props.inventoryDetailById( this.state.UserId);
  }
  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ openDropDown: true }, () => {
      document.addEventListener("click", this.hideDropdownMenu);
    });
  }

  hideDropdownMenu() {
    this.setState({ openDropDown: false }, () => {
      document.removeEventListener("click", this.hideDropdownMenu);
    });
  }
  handleClick(event, row) {
    // this.setState({anchorE2: event.currentTarget});
  }
  checkboxval(input) {
    const id = input?.id;
    const sold = input?.sold;
    if (
      this.props.InventoryList &&
      this.props.InventoryList.data &&
      this.props.InventoryList.data.length > 0 &&
      id
    ) {
      const inventoryList = this.props.InventoryList.data;
      console.log(this.props, "heloo inven");
      const indexPosition = inventoryList.findIndex(singleInventory => {
        console.log('Match Status', singleInventory.inventoryid, id, '::', singleInventory.inventoryid === id)
        if (singleInventory.inventoryid === id) {
          console.log('inventory matched', singleInventory)
          return singleInventory
        }
      })
      if (indexPosition > -1) {
        if (sold === true) {
          this.props.InventoryList.data[indexPosition].sold = 1;
        } else {
          this.props.InventoryList.data[indexPosition].sold = 0;
        }
      }
    } else if (
      this.props.InventoryList &&
      this.props.InventoryList.data &&
      this.props.InventoryList.data.length > 0
    ) {
      for (let i = 0; i < this.props.InventoryList.data.length; i++) {
        if (this.props.InventoryList.data[i].sold == 1) {
          this.setState({ soldbtn: true });
          console.log(this.state.soldbtn, "true button");
        } else {
          this.setState({ soldbtn: false });
          console.log(this.state.soldbtn, "false button");
        }
      }
    }
    if (id && this.state.updatedInvenentryList) {
      let inventoryList = this.state.updatedInvenentryList;
      const indexPosition = inventoryList.findIndex(singleInventory => {
        console.log('Match Status', singleInventory.inventoryid, id, '::', singleInventory.inventoryid === id)
        if (singleInventory.inventoryid === id) {
          console.log('inventory matched', singleInventory)
          return singleInventory
        }
      })
      if (indexPosition > -1) {
        if (sold === true) {
          inventoryList[indexPosition].sold = 1;
        } else {
          inventoryList[indexPosition].sold = 0;
        }
        console.log("::::::after::::::");
        console.log(inventoryList[indexPosition]);
        this.setState({ updatedInvenentryList: inventoryList });
      }
      // if (inventoryList && inventoryList.length > 0) {
      //   for (let i = 0; i < inventoryList.length; i++) {
      //     if (id && inventoryList[i].inventoryid === id) {
      //       console.log("switch enable", id, sold);
      //       console.log(inventoryList[i]);
      //       if (sold === true) {
      //         inventoryList[i].sold = 1;
      //       } else {
      //         inventoryList[i].sold = 0;
      //       }
      //       console.log("::::::after::::::");
      //       console.log(inventoryList[i]);
      //       this.setState({ updatedInvenentryList: inventoryList });
      //     }
      //   }
      // }
    }

    console.log(this.state.soldbtn, "normal");
  }

  componentWillReceiveProps(newProps) {
    if (newProps.currentUser && !this.state.currentUser) {
      this.setState({
        ...this.state,
        currentUser: newProps.currentUser,
      });
      const data = {
        brand_id: this.state.brand_id,
        brand_description: this.state.brand_description,
        sold: this.state.soldStatus,
        // inventory_status: this.state.inventoryStatus,
        inventoryxlid: this.state.inventoryxlid,
        inventory_type: this.state.InventoryType,
        inventory_status: this.state.InventoryStatus,
        purchasedate:
          this.state.purchasedate === 0
            ? "ALL"
            : this.state.purchasedate > 180
              ? "UNKNOWN"
              : this.state.purchasedate,
        type: this.state.type,
        category: this.state.InventoryCategory
      };
      if (data.type === "ALL") {
        delete data.type
      }
      if (data.category === "ALL") {
        delete data.category
      }
      this.props.fetchInventoryWatches(this.state.userData?.accountId, data);
      this.props.fetchInventoryExcelList(newProps.currentUser.userId);
      //this.props.inventoryDetailById(newProps.currentUser.inventoryid);
      const bulkUploadId = this.props?.match?.params.bulkUploadId || null;
      if (bulkUploadId) {
        this.SearchInventoryWatchesdetails(newProps.currentUser);
      }
    }
  }
  handleChangeOfBrandByBrandName = (event) => {
    let brand = event.target.value;
    console.log(event.target.value);
    this.setState({
      brand_id: brand.brandid,
      brand_description: brand.brandname,
    });
    let brandName = "";
    if (brand.brandid == "ALL") {
      brandName = "";
    } else {
      brandName = brand.brandid;
    }
    this.setState({
      BrandName: brandName,
      modledata: brand.brandname,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);

    // this.props.filterBySearch(data);
  };

  valueLabelFormat = (value) => {
    let scaledValue = value;

    if (value === "0") {
      scaledValue = "ALL";
    } else if (value > 180) {
      scaledValue = "UNKNOWN";
    } else {
      scaledValue = value;
    }
    return `${scaledValue}`;
  };

  inventoryPurchaseDateInterval = (value) => {
    let purchase = value;
    console.log("purchase data input", purchase);

    // let purchaseDate = "";
    // if (purchase === "ALL") {
    //     purchaseDate = "0";
    // } else {
    //     purchaseDate = purchase;

    // }

    this.setState({
      purchasedate: purchase,
    });

    // setTimeout(() => {

    //     this.SearchInventoryWatchesdetails();

    // }, 1000);
  };

  handleChangeOfBulkUploadId(event) {
    console.log(event.target.value, "event.target.value ");
    // let data = event.target.value
    let inventorybulkxlid = "";
    if (event.target.value === "ALL") {
      inventorybulkxlid = "";
    } else {
      inventorybulkxlid = event.target.value;
    }
    this.setState({
      inventoryxlid: inventorybulkxlid,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);

    // window.open("/myinventory/" + event.target.value)
  }
  handleClose(option) {
    this.setState({ open: false });
    window.open("/create/invoice");
  }

  removeSoldUnsold() {
    this.setState({
      soldStatus: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }
  removeInventoryStatus() {
    this.setState({
      inventoryStatus: "",
      InventoryStatus: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }
  removeBulkUploadId() {
    this.setState({
      inventoryxlid: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  removeInventoryType() {
    this.setState({
      InventoryType: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }

  // removeInventoryStatus() {
  //   this.setState({
  //     InventoryStatus: "",
  //     searchEnabled: false,
  //   });
  //   setTimeout(() => {
  //     this.SearchInventoryWatchesdetails();
  //   }, 1000);
  //   // setTimeout(() => {
  //   //   this.setState({ loaded: true });
  //   // }, 1000);
  //   // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
  //   // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  // }

  removetype() {
    this.setState({
      type: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }
  removeInventoryCategory() {
    this.setState({
      InventoryCategory: "",
      searchEnabled: false,
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // setTimeout(() => {
    //   this.setState({ loaded: true });
    // }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
  }
  removebrandname() {
    // console.log("clicked");
    // var element = document.getElementsByClassName("brandClass")[0];
    // element.classList.remove("MuiInputLabel-shrink");
    //  $('.brandLangend .PrivateNotchedOutline-legendLabelled-87 > span').css('display','none')
    this.props.brandList();
    console.log("remove");
    this.setState({
      modledata: "",
      removedbrndname: "",
      searchEnabled: false,
      brand_id: "",
      brand_description: "",
      BrandName: "",
      loaded: false,
      // band_material: this.state.CaseMaterial,
      // case_size: this.state.CafetchInventoryWatchesseSize,
      // case_material: this.state.case_material,
      page_no: "1",
      limit: "12",
    });
    // setTimeout(() => {
    //   this.setState({ loaded: true });

    // }, 1000);
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
    // this.props.fetchInventoryExcelList(this.state.currentUser.userId);
    // this.props.fetchInventoryWatches(this.state.userData?.accountId);
    // let data = {
    //     "brand_id": "",
    // "band_material": this.state.CaseMaterial,
    // "case_size": this.state.CaseSize,
    // "case_material": this.state.case_material,
    //     "page_no": "1",
    //     "limit": "100",
    // }
    // console.log(data)
    // searchApi.productSeach(data, this.state.producId).then((res) => {
    //     console.log('watchres', res.data.data);
    //     this.setState({
    //         browseWatchesInfo: res.data.data
    //     })
    //     console.log(this.state.browseWatchesInfo, "bro")
    //     if (res.data.success === 1) {
    //         this.setState({
    //             browseWatchesInfo: res.data.data
    //         })
    //         console.log(this.state.browseWatchesInfo)
    //     } else {
    //         this.setState({
    //             errorMessage: res.message
    //         })
    //     }

    // })
  }

  handleChangeOfInventoryDetailById = (id) => {
    console.log(id, this.props);
    const { history } = this.props;
    this.props.history.push(
      "/dealer-inventory-detail/" + id,
      {
        inventoryCacheState: { ...this.state }
      }
    );

    {
      this.props.inventoryDetailById(id);
    }
  };

  browseWatches() {
    this.setState({
      nonsearchstring: false,
    });
    this.props.fetchSearchBrowseWatches(this.state.searchString);
    console.log("slectedata", this.state.selected, this.state.searchString);
    // if (this.state.selected.product && this.state.selected.product.model) {
    //   // this.props.history.push(
    //   //   "/browsewatches/" + this.state.selected.product.model
    //   // );
    //   this.props.fetchSearchBrowseWatches(this.props.match.params.productId);
    // }
  }
  onProductChange = _.debounce((e, v, r) => {
    // this.props.onTextInputAdd(text);
    console.log(e);
    if (v) {
      switch (e) {
        case "product_selected":
          this.setState({
            ...this.state,
            selected: { product: v },
          });
          // this.props.history.push('/browsewatches/' + v.model)
          break;
        case "input_changed":
          this.state.autocomplete.currentInput = v;

          this.state.autocomplete.loading = true;
          this.setState({
            ...this.state,
          });
          if (v.length >= 3 && r == "input") {
            this.props.searchProducts({ searchText: v }, (error, res) => { });
          }
          break;
      }
    } else {
      console.log("dddd");
    }
  }, 600);
  onChangeOfFirstName = (event) => {
    this.setState({
      key: event.target.value,
    });
  };
  autocomplete(evt) {
    console.log(evt.target.value);

    const inventoryList = this.props.InventoryList.data;

    console.log(evt.target.value);
    let text = evt.target.value;
    this.setState({ searchString: text });
    this.setState({ loading: true });
    this.getAutocomplete(text);
  }
  getAutocomplete = (evt) => {
    console.log(evt.target.value);

    // const inventoryList = this.props.InventoryList.data;s
    // this.props.searchProducts({ searchText: evt }, (error, res) => {
    //     this.setState({ searchItems: this.state.autocomplete.data });
    //     this.setState({ loading: false });
    // });

    console.log(this.state.autocomplete.data);
  };

  hanldeKeyup(evt) {
    console.log(evt.target.value);

    //if (evt.keyCode === 27) {
    //  this.setState({ searchItems: [] });
    //   return false;
    //  }
  }

  hanldeKeydown(evt) {
    /*
        const { cursor, searchItems } = this.state;
        // arrow up/down button should select next/previous list element
        if (evt.keyCode === 38 && cursor > 0) {
          this.setState((prevState) => ({
            cursor: prevState.cursor - 1,
          }));
        } else if (evt.keyCode === 40 && cursor < searchItems.length - 1) {
          this.setState((prevState) => ({
            cursor: prevState.cursor + 1,
          }));
        }
        if (evt.keyCode === 13) {
          let currentItem = searchItems[cursor];
          if (currentItem !== undefined) {
            const { name, code, rate, unit } = currentItem;
            this.setState({ item: { name, code, rate, unit }, searchItems: [] });
          }
        }
        if (evt.keyCode === 8) {
          this.setState({ item: { name: "", code: "", rate: "", unit: "" } });
        }*/
  }

  selectItem(id) {
    const { searchItems } = this.state;
    let selectedItem = searchItems.find((item) => item.model === id);
    // const { code, name, unit, rate } = selectedItem;
    //  this.setState({ item: {  name, unit, rate } });
    this.setState({ searchString: selectedItem.searchattr });
    this.setState({ searchStringdetails: selectedItem.searchattr });
    console.log(this.state.searchString);
    this.setState({ autocomplete: { data: [] } });
    // this.props.fetchSearchBrowseWatches(id);
    const inventoryList = this.props.InventoryList.data;
  }

  handleListKeydown(evt) {
    console.log(evt);
  }

  handleChange(evt) {
    this.setState({ item: { [evt.target.name]: evt.target.value } });
  }

  SearchInventoryWatchesdetails(userObject = null) {
    const userId = this.state.currentUser && this.state.currentUser.userId;
    const id = userId ? userId : userObject ? userObject.userId : null;
    this.setState({ loaded: false });
    let keySearch = this.state.key;
    let searchData = "";
    if (keySearch) {
      searchData = keySearch.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
      searchData = searchData.replaceAll("-", "");
    }

    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.brand_description,
      key: searchData,
      sold: this.state.soldStatus,
      // inventory_status: this.state.inventoryStatus,
      inventoryxlid: this.state.inventoryxlid,
      inventory_type: this.state.InventoryType,
      inventory_status: this.state.InventoryStatus,
      purchace_date:
        this.state.purchasedate === 0
          ? "ALL"
          : this.state.purchasedate > 180
            ? "UNKNOWN"
            : this.state.purchasedate,
      type: this.state.type,
      category: this.state.InventoryCategory
    };
    if (data.type === "ALL") {
      delete data.type
    }
    if (data.category === "ALL") {
      delete data.category
    }
    if (
      data.brand_id !== "" ||
      data.key !== "" ||
      data.sold !== "" ||
      data.inventoryxlid !== "" ||
      data.inventory_type !== "" ||
      data.inventory_status !== "" ||
      data.purchasedate !== "" ||
      data.type !== "ALL" ||
      data.category !== "ALL"
    ) {
      this.setState({ searchEnabled: true });
    }
    if (data.sold === "") {
      data.sold = "ALL"
    }
    // const inventoryDetails = this.props.SearchInventoryWatches(data, this.state.currentUser.userId);
    // console.log(inventoryDetails,'inventoryDetails===========')
    searchinventry.addinvetrysearchdataa(data, this.state.userData?.accountId).then((res) => {
      console.log("res", res.data);
      if (res.data && res.data.inventoryData) {
        this.setState({ inventoryData: res.data.inventoryData, page: 0, loaded: true });
      } else {
        this.setState({ inventoryData: [], page: 0, loaded: true });
      }
      if (res.data.success === 1) {
        this.setState({
          updatedInvenentryList: res.data.data,
          loaded: true,
        });
      } else if (res.data.success === 0) {
        this.setState({
          updatedInvenentryList: [],
          errorMessage: res.data.message,
          loaded: true,
        });
      } else {
        this.setState({
          errorMessage: res.message,
          loaded: true,
        });
      }
    });
  }

  downloadInventory(type) {
    const userId = this.state.currentUser && this.state.currentUser.userId;
    const id = userId ? userId : null;
    this.setState({ downloadloading: true });
    let keySearch = this.state.key;
    let searchData = "";
    if (keySearch) {
      searchData = keySearch.replace(/[&\/\\#,+!@^&()$~%.'":;-?<>{}]/g, "");
      searchData = searchData.replaceAll("-", "");
    }

    let data = {
      brand_id: this.state.brand_id,
      brand_name: this.state.brand_description,
      key: searchData,
      sold: this.state.soldStatus === "" ? "ALL" : this.state.soldStatus,
      // inventory_status: this.state.inventoryStatus,
      inventoryxlid: this.state.inventoryxlid,
      inventory_type: this.state.InventoryType,
      inventory_status: this.state.InventoryStatus,
      purchasedate:
        this.state.purchasedate === 0
          ? "ALL"
          : this.state.purchasedate > 180
            ? "UNKNOWN"
            : this.state.purchasedate,
      userid: id,
      accountid: this.state.userData?.accountId ? this.state.userData.accountId : null,
      type: this.state.type === "" ? "ALL" : this.state.type,
      category: this.state.InventoryCategory,
      fileType: type
    };
    console.log(data);
    if (data.type === "ALL") {
      delete data.type
    }
    if (data.category === "ALL") {
      delete data.category
    }
    if (data.sold === "") {
      data.sold = "ALL"
    }
    console.log("userId", id);
    // const inventoryDetails = this.props.SearchInventoryWatches(data, this.state.currentUser.userId);
    // console.log(inventoryDetails,'inventoryDetails===========')
    searchinventry.downloadInventory(data, id).then((res) => {
      console.log("res", res);
      if (res && res.status === 0) {
        toast("No Inventories for download.", {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      this.setState({ downloadloading: false });
    });
  }
  handleSwitch = (row, event) => {
    console.log("changeRangeName", row);
    const id = row.inventoryid;
    const checkstaus = event.target.checked;
    console.log(checkstaus, "hii check status");
    console.log(checkstaus);
    this.setState({
      rowDetails: row
    })
    if (checkstaus === true) {
      console.log(id, "id 33");
      this.setState({
        soldbtn: true,
        disable: true,
      });
      localStorage.setItem("sold_status", "1");
      localStorage.setItem("inventory_id", id);
      this.checkboxval({ id: id, sold: true });
      this.setState({ soldbtn: true });
    } else {
      this.setState({ soldbtn: false });
      localStorage.setItem("sold_status", "0");
      localStorage.setItem("inventory_id", id);
      console.log(this.state.soldbtn, "false");
      this.checkboxval({ id: id, sold: false });
      this.setState({ soldbtn: false });
    }
    this.setState({
      openReasonChange: true
    })
  }
  handleCloseOfReasonChange() {
    const id = this.state.rowDetails.inventoryid;
    let sold = this.state.rowDetails.sold;
    console.log(sold, 'sold');
    if (sold == 0) {
      this.checkboxval({ id: id, sold: true });

    } else {
      this.checkboxval({ id: id, sold: false });
    }
    this.setState({
      openReasonChange: false,
    })
  }

  changeRangeName = (reason) => {
    // const id = row.inventoryid;
    // const { productid, serialno } = row;

    console.log("changeRangeName1", this.state.rowDetails);
    const id = this.state.rowDetails.inventoryid;
    const { productid, serialno } = this.state.rowDetails;
    let data = {
      inventory_id: localStorage.getItem("inventory_id"),
      sold_status: localStorage.getItem("sold_status"),
      productId: productid,
      serialNumer: serialno,
      listed_on: this.state.listed_on,
      sold_unsold_reason: reason,
    };
    console.log(reason, "hello reason");
    searchinventry.productsold(data).then((res) => {
      console.log("res", res);
      if (res.data.success === 1) {
        this.setState({
          openReasonChange: false
        })
        console.log(this.state.openReasonChange, "hi state");
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.SUCCESS,
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.SearchInventoryWatchesdetails();
        localStorage.removeItem("inventory_id");
        localStorage.removeItem("sold_status");

      } else {
        this.setState({
          openReasonChange: false
        })
        localStorage.removeItem("inventory_id");
        localStorage.removeItem("sold_status");
        toast(res.data.message, {
          autoClose: 5000,
          type: toast.TYPE.ERROR,
          position: toast.POSITION.BOTTOM_CENTER,
        });


      }

    });

  };

  copy = () => {
    const el = document.createElement(
      "https://api.watchops.com/inventories/{this.props.currentUser.userId}/c24-importer.xml"
    );
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.setState({
      setCopied: true,
    });
  };

  handleChangeOfSoldUnSold(event) {
    let brand = event.target.value;
    console.log("brand", brand);
    this.setState({
      soldStatus: brand,
    });

    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }
  handleChangeOfInventoryStatus(event) {
    let status = event.target.value;
    this.setState({
      inventoryStatus: status,
    });

    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  }
  handleChangePage = (event, newpage) => {
    this.setState({ page: newpage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  handleClearButton = () => {
    this.setState({
      key: "",
    });
    setTimeout(() => {
      this.SearchInventoryWatchesdetails();
    }, 1000);
  };
  endAdornment = () => {
    const values = this.state.key;
    if (values) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={() => {
              this.handleClearButton();
            }}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    } else {
      return (
        <InputAdornment position="end">
          <IconButton
            className="primary-bg color-white"
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={this.SearchInventoryWatchesdetails}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      );
    }
  };
  inventoryrender() {
    const { searchItems, cursor, item, handleChange } = this.state;
    let inventoryList = [];
    if (
      this.state.searchEnabled &&
      (this.state.key !== "" ||
        this.state.brand_id !== "" ||
        this.state.soldStatus !== "" ||
        this.state.inventoryxlid !== "" ||
        this.state.InventoryType !== "" ||
        this.state.InventoryStatus !== ""
      )
    ) {
      inventoryList = this.state.updatedInvenentryList;
    } else {
      inventoryList = this.props.InventoryList.data;
    }
    const brandInfo = this.props.brandInfo.data;
    const inventoryXlIdInfo = this.props.inventoryXlIdInfo.data;

    return (
      <div className="space-bottom-30 someextrahight" style={{ marginTop: '20px' }}>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography type="title" color="inherit">
                <h1><b>MY DEALER INVENTORY</b></h1>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ margin: "-10px" }}>
            {/* <Grid item xs={12} sm={6} md={12}> */}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={4}>
                    <div>
                      <span
                        onClick={this.removebrandname}
                        style={{ float: "right" }}
                      >
                        <Icon
                          className={
                            this.state.modledata ? "fa fa-times-circle" : ""
                          }
                        />
                      </span>

                      <FormControl variant="outlined" className="formControl">
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className="font-14"
                        >
                          Brand
                        </InputLabel>
                        <Select
                          style={{ color: "black" }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={this.handleChangeOfBrandByBrandName}
                          label="Brand"
                        >
                          <MenuItem value="ALL">All</MenuItem>
                          {brandInfo &&
                            brandInfo.map((row) => (
                              <MenuItem value={row}>{row.brandname}</MenuItem>
                            ))}
                        </Select>
                        <Typography
                          variant="h6"
                          className="color-383838"
                          style={{ fontSize: "15px" }}
                        >
                          {this.state.modledata
                            ? this.state.modledata
                            : this.state.removedbrndname}{" "}
                        </Typography>
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div>
                      <span
                        onClick={this.removetype}
                        style={{ float: "right" }}
                      >
                        <Icon
                          className={
                            this.state.type ? "fa fa-times-circle" : ""
                          }
                        />
                      </span>
                      <FormControl variant="outlined" className="formControl">
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className="font-14"
                        >
                          Type
                        </InputLabel>
                        <Select
                          style={{ color: "black" }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={(e) => {
                            if (e.target.value !== 'misc_product') {
                              this.setState({ type: e.target.value, InventoryCategory: "" });
                            } else {
                              this.setState({ type: e.target.value });
                            }
                            setTimeout(() => {
                              this.SearchInventoryWatchesdetails();
                            }, 1000);
                          }
                          }
                          label="Brand"
                          value={this.state.type}
                        >
                          <MenuItem value="ALL">All</MenuItem>
                          <MenuItem value="standand_and_misc">Standard and Misc Watch</MenuItem>
                          <MenuItem value="standard_watch">Standard Watch</MenuItem>
                          <MenuItem value="misc_watch">Misc Watch</MenuItem>
                          <MenuItem value="misc_product">Misc Product</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <Typography
                      variant="h6"
                      className="color-383838"
                      style={{ fontSize: "15px" }}
                    >
                      {this.state.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <div>
                      <span
                        onClick={this.removeInventoryCategory}
                        style={{ float: "right" }}
                      >
                        <Icon
                          className={
                            this.state.InventoryCategory ? "fa fa-times-circle" : ""
                          }
                        />
                      </span>

                      <FormControl variant="outlined" className="formControl">
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className="font-14"
                        >
                          Category
                        </InputLabel>
                        <Select
                          style={{ color: this.state.type !== 'misc_product' ? "#bbb" : "black", background: this.state.type !== 'misc_product' ? "#ccc" : "#fff" }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          label="Inventory Category"
                          defaultValue={this.state.InventoryCategory}
                          value={this.state.InventoryCategory}
                          disabled={this.state.type !== 'misc_product'}
                          onChange={(e) => {
                            this.setState({ InventoryCategory: e.target.value });
                            setTimeout(() => {
                              this.SearchInventoryWatchesdetails();
                            }, 1000);
                          }}
                        >
                          <MenuItem value="ALL">All</MenuItem>
                          <MenuItem value="watch related accessories">Watch Accessories</MenuItem>
                          <MenuItem value="apparel">Apparel</MenuItem>
                          <MenuItem value="watch parts">Watch Part (s)</MenuItem>
                          <MenuItem value="jewelry">Jewelry</MenuItem>
                          <MenuItem value="stones">Gem (s)</MenuItem>
                          <MenuItem value="others">Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6}>
                    <div>
                      <span
                        onClick={this.removeSoldUnsold}
                        style={{ float: "right" }}
                      >
                        <Icon
                          className={
                            this.state.soldStatus ? "fa fa-times-circle" : ""
                          }
                        />
                      </span>
                      <FormControl variant="outlined" className="formControl">
                        <InputLabel
                          id="demo-simple-select-outlined-label"
                          className="font-14"
                        >
                          Dealer
                        </InputLabel>
                        <Select
                          style={{ color: "black" }}
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          onChange={this.handleChangeOfSoldUnSold}
                          label="Brand"
                          value={this.state.soldStatus}
                        >
                          <MenuItem value="1">Watchops</MenuItem>
                          <MenuItem value="0">Amazon</MenuItem>
                          <MenuItem value="Void">Kyyba</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <Typography
                      variant="h6"
                      className="color-383838"
                      style={{ fontSize: "15px" }}
                    >
                      {" "}
                      {/* {this.state.soldStatus}{" "} */}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      className="form-width-99"
                      onSubmit={(e) => {
                        e.preventDefault();
                        this.SearchInventoryWatchesdetails();
                      }}
                    >
                      <div style={{ marginTop: "30px", marginLeft: "15px" }}>
                        <Paper
                          // component="form"
                          sx={{ p: "2px 4px" }}
                          style={{ height: "50px", display: "flex" }}
                        >
                          <InputBase
                            fullWidth
                            sx={{ ml: 3, flex: 1 }}
                            inputProps={{
                              "aria-label": "Enter Serial no / Model no / Notes / Inventory Id / Inventory Title",
                            }}
                            placeholder="Enter Serial no / Model no / Notes / Inventory Id / Inventory Title"
                            // defaultValue={this.state.key}
                            onChange={this.onChangeOfFirstName}
                            // onChange={onSearchClick}
                            //  onChange={(e) => {
                            //   onSearchClick(e);
                            //  }}
                            value={this.state.key}
                            endAdornment={this.endAdornment()}
                          />
                        </Paper>
                      </div>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <br />
          {this.state.loaded === false ? (
            <div class="d-spinner">
              <SpinnerRoundFilled
                size={51}
                thickness={125}
                speed={80}
                color="rgba(57, 107, 172, 1)"
                className="spp"
                enabled={this.state.loaded == false}
              />
            </div>
          ) : (
            <Grid container spacing={1}>
              {this.state.inventoryData &&
                this.state.inventoryData.length > 0 &&
                this.state.inventoryxlid !== "" && (
                  <div style={{ margin: "auto", width: "100%" }}>
                    <Container style={{ marginBottom: "30px" }}>
                      <MaterialTable
                        title={`Bulk Upload Summary`}
                        data={this.state.inventoryData}
                        columns={[
                          { field: "status", title: "STATUS" },
                          {
                            field: "filePath",
                            title: "DOWNLOAD FILE",
                            render: (rowData) => (
                              <Link
                                href={`${rowData.filePath}`}
                                target="_blank"
                              >
                                {rowData.filePath}
                              </Link>
                            ),
                          },
                        ]}
                      />
                    </Container>
                  </div>
                )}
              {inventoryList &&
                inventoryList.length === 0 &&
                !this.state.loaded ? (
                <Container style={{ marginBottom: "30px" }}>
                  <Typography
                    variant="body2"
                    className="textSecondary color-292C2F"
                    component="p"
                    align="center"
                  >
                    <b>No Watches Added Here!</b>
                  </Typography>
                </Container>
              ) : (
                ""
              )}
              {inventoryList && inventoryList.length > 0 ? (
                inventoryList &&
                inventoryList
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                    this.state.rowsPerPage
                  )
                  .map((row) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        className=""
                        key={row.inventoryid}
                      >
                        <Link className="">
                          <Card
                            className="inventrybox"
                            style={{
                              cursor: "pointer",
                              //   boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
                              "&:hover": {
                                boxShadow:
                                  "0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)",
                              },
                              background: (row.type && (row.type === "misc_watch" || row.type === "misc_product")) ? "antiquewhite" : "white",
                              paddingTop: '20px',
                              paddingBottom: '20px'
                            }}
                          //   onClick={() =>this.handleChangeOfInventoryDetailById(row.inventoryid)}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                sm={4}
                                md={4}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  className="text-center1"
                                  style={{
                                    paddingLeft: "30px",
                                  }}
                                >
                                  {(row.sold_unsold_reason || row.sold_unsold_reason) ? (<Tooltip
                                    title={
                                      (row.sold == 1 ?
                                        `${this.state.soldText}${row.sold_unsold_reason}` :
                                        `${this.state.unsoldText}${row.sold_unsold_reason}`
                                      )
                                    }
                                    placement="right"
                                  >

                                    <img
                                      src={
                                        row?.imagepaths
                                          ? (row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed || row?.imagepaths?.wrist_shot)
                                          : (row?.product_imagepaths?.images
                                            ? row?.product_imagepaths?.images
                                            : imageNotAvailable)
                                      }
                                      alt="rolex"
                                      className="img-responsive1 inventry-img"
                                      onClick={() =>
                                        this.handleChangeOfInventoryDetailById(
                                          row.inventoryid
                                        )
                                      }
                                    />
                                  </Tooltip>) : (<img
                                    src={
                                      row?.imagepaths
                                        ? (row?.imagepaths?.front || row?.imagepaths?.back || row?.imagepaths?.left || row?.imagepaths?.right || row?.imagepaths?.free_form || row?.imagepaths?.buckle_opened || row?.imagepaths?.buckle_closed || row?.imagepaths?.wrist_shot)
                                        : (row?.product_imagepaths?.images
                                          ? row?.product_imagepaths?.images
                                          : imageNotAvailable)
                                    }
                                    alt="rolex"
                                    className="img-responsive1 inventry-img"
                                    onClick={() =>
                                      this.handleChangeOfInventoryDetailById(
                                        row.inventoryid
                                      )
                                    }
                                  />)}

                                </div>
                              </Grid>
                              <Grid item xs={12} sm={8} md={8}>
                                <div className="space-1 text-center1">
                                  <Grid container spacing={2}>
                                    {row.voidreason ? (
                                      <Tooltip title={row.voidreason}>
                                        <Grid
                                          item
                                          xs={12}
                                          sm={12}
                                          md={12}
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          {
                                            row.status &&
                                              row.status === "Void" ? (
                                              <Chip
                                                className="void-status"
                                                size="small"
                                                label={"Void"}
                                              />
                                            ) : (
                                              <></>
                                            )
                                            // <Chip className='active-status'  size="small" label={'Active'}/>
                                          }
                                        </Grid>
                                      </Tooltip>
                                    ) : (
                                      <></>
                                    )}
                                    <Grid item xs={12} sm={12} md={12}>
                                      <div
                                        onClick={() =>
                                          this.handleChangeOfInventoryDetailById(
                                            row.inventoryid
                                          )
                                        }
                                      >
                                        <Typography
                                          variant="subtitle2"
                                          className="textSecondary color-292C2 MuiTypography-textupper"
                                          component="p"
                                        // style={{width:'275px'}}
                                        >
                                          <b>
                                            {

                                              (row.type === null) ?
                                                // row?.prodattr?.reference
                                                //   ? row?.serialno
                                                //     ? row.prodattr.reference +
                                                //       " - " +
                                                //       row?.serialno
                                                //     : row.prodattr.reference
                                                //   : ""
                                                (
                                                  row?.brandname
                                                    ? row?.brandname + (
                                                      row?.prodattr?.reference
                                                        ? " - " + row?.prodattr?.reference + (
                                                          row?.inventoryid ?
                                                            " - " + row.inventoryid : ""
                                                        )
                                                        : ""
                                                    )
                                                    : "" + (
                                                      row?.prodattr?.reference
                                                        ? row?.prodattr?.reference + (
                                                          row?.inventoryid
                                                            ? " - " + row?.inventoryid
                                                            : "" + (
                                                              row?.inventoryid
                                                                ? row?.inventoryid
                                                                : ""
                                                            )
                                                        )
                                                        : row?.inventoryid
                                                    )
                                                )
                                                : row?.prodattr?.brand_name
                                                  ? row?.prodattr?.brand_name + (
                                                    row?.prodattr?.model_number
                                                      ? " - " + row?.prodattr?.model_number + (
                                                        row?.inventoryid ?
                                                          " - " + row.inventoryid : ""
                                                      )
                                                      : ""
                                                  )
                                                  : "" + (
                                                    row?.prodattr?.model_number
                                                      ? row?.prodattr?.model_number + (
                                                        row?.inventoryid
                                                          ? " - " + row?.inventoryid
                                                          : "" + (
                                                            row?.inventoryid
                                                              ? row?.inventoryid
                                                              : ""
                                                          )
                                                      )
                                                      : row?.inventoryid
                                                  )

                                            }
                                          </b>
                                        </Typography>
                                        {/* <Typography
                                          variant="body2"
                                          className="textSecondary color-292C2F"
                                          component="p"
                                          align="center"
                                        >
                                          <b>
                                            {(row.type === null ) ? (row && row.brandname
                                              ? row.brandname
                                              : "") 
                                              : ""
                                            }
                                          </b>
                                        </Typography> */}
                                      </div>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12}>
                                      <div
                                        onClick={() =>
                                          this.handleChangeOfInventoryDetailById(
                                            row.inventoryid
                                          )
                                        }
                                      >
                                        {row.type !== 'misc_product' && (
                                          <>
                                            <Typography variant="p">
                                              Box :{" "}
                                              {(row?.warrantybox && row.warrantybox === '1'
                                                ? "Yes"
                                                : "No")
                                              }
                                            </Typography>
                                            <br />
                                            <Typography variant="p">
                                              Warranty  :{" "}
                                              {row.warrantypaper ? "Yes"
                                                : "No"
                                              }
                                            </Typography>
                                            <br />
                                            <Typography variant="p">
                                              Condition  :{" "}
                                              {row.condition && row.condition === 4 ? 'New' :
                                                row.condition && row.condition === 3 ? 'Retail Ready' :
                                                  row.condition && row.condition === 2 ? 'Mint' :
                                                    'Pre-owned'
                                              }
                                            </Typography>
                                            <br />
                                            {row.targetwholesaleprice && (
                                              <>
                                                <Typography variant="p">
                                                  Target Wholesale Price:{" "}
                                                  $ {row.targetwholesaleprice}
                                                </Typography>
                                                <br />
                                              </>
                                            )}

                                            <Typography variant="p">
                                              Inventory Status :{" "}
                                              {
                                                (row?.inventory_status
                                                  ? InventoryStatusLookup[row?.inventory_status]
                                                  : "---")
                                              }
                                            </Typography>
                                          </>
                                        )}
                                        {row.type === 'misc_product' && (
                                          <>
                                            <Typography variant="p">
                                              Product Name:{" "}
                                              {(row &&
                                                row
                                                  ?.technical_details
                                                  ?.product_title ?
                                                row
                                                  ?.technical_details
                                                  ?.product_title
                                                : "---")
                                              }
                                            </Typography>
                                            <br />
                                            <Typography variant="p" className="text-capitalize">
                                              Category:{" "}
                                              {(row &&
                                                row.category ?
                                                row.category
                                                : "---")
                                              }
                                            </Typography>
                                            <br />
                                            <Typography variant="p">
                                              Condition  :{" "}
                                              {row.condition && row.condition === 1 ? 'New' :
                                                'Used'
                                              }
                                            </Typography>
                                            <br />
                                            {row.targetwholesaleprice && (
                                              <>
                                                <Typography variant="p">
                                                  Target Wholesale Price:{" "}
                                                  $ {row.targetwholesaleprice}
                                                </Typography>
                                                <br />
                                              </>
                                            )}

                                            <Typography variant="p">
                                              Inventory Status :{" "}
                                              {
                                                (row?.inventory_status
                                                  ? InventoryStatusLookup[row?.inventory_status]
                                                  : "---")
                                              }
                                            </Typography>
                                          </>
                                        )}
                                      </div>
                                    </Grid>
                                    {row.sold == 1 ? (
                                      <div
                                        style={{
                                          opacity: "0.4",
                                        }}
                                      >
                                        <Grid container spacing={1}>
                                          <Grid item xs={6} sm={6} md={5}>
                                            <div
                                              style={{
                                                marginTop: "7px",
                                                marginLeft: "10px",
                                                width: "77px",
                                              }}
                                            >
                                              {/* <img src={ebay} alt="ebay" className="img-responsive1" /> */}
                                            </div>
                                          </Grid>
                                          <Grid item xs={6} sm={6} md={5}>

                                          </Grid>
                                        </Grid>
                                      </div>
                                    ) : null}

                                    <Grid item xs={12} sm={12} md={1}></Grid>

                                  </Grid>
                                </div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Link>
                      </Grid>
                    );
                  })
              ) : (
                <Box
                  component="span"
                  sx={{
                    border: "2px dashed grey",
                    width: "100%",
                    padding: "50px 72px",
                    marginTop: "20px",
                  }}
                >
                  {" "}
                  <Container>
                    <Typography
                      variant="h6"
                      className="color-BCBCBC"
                      component="p"
                      align="center"
                    >
                      <b>No Records Found</b>
                    </Typography>
                  </Container>
                </Box>
              )}
            </Grid>
          )}
          {this.state.open && (
            <InvoiceModal
              open={this.state.open}
              handleClose={this.handleClose}
            />
          )}
          {this.state.openReasonChange && (
            <ReasonChange
              open={this.state.openReasonChange}
              handleClose={this.handleCloseOfReasonChange}
              changeRangeName={this.changeRangeName}
            />
          )

          }
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={inventoryList.length}
            rowsPerPage={this.state.rowsPerPage}
            SelectProps={{
              native: true
            }}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Container>

        {/* </Loader> */}
      </div>
    );
  }
  render() {
    return (
      <div>
        <ContentWrapper contentView={this.inventoryrender()} >
        </ContentWrapper>

      </div>
    )
  }
}

function mapStateToProps(state) {
  const { InventoryList, auth, browseWatchesInfo, inventoryDetailByIdInfo } =
    state;
  return {
    InventoryList: InventoryList.InventoryList,
    currentUser: auth.currentUser,
    brandInfo: browseWatchesInfo.brandInfo,
    inventoryXlIdInfo: InventoryList.inventoryXlIdInfo,
    // inventoryDetailByIdInfo: inventoryDetailByIdInfo.inventoryDetailByIdInfo
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInventoryWatches,
      filterBySearch,
      brandList,
      inventoryDetailById,
      fetchInventoryExcelList,
      SearchInventoryWatches,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DealerInventoryComponent);
